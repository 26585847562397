import swal from 'sweetalert';
import moment from 'moment';

import {toastError, toastSuccess} from './toast_actions';
import {doGetV2, doPutV2} from '../api/base/base_api_v2_helper';
import {doGet, doPost, doPut} from '../api/base/base_api_helper';
export function sendIndividualEmail(solicitationCode) {
  return (dispatch, getState) => {
    swal(
      {
        title: `Enviar resumo para cliente`,
        text: 'Informe o email do cliente',
        type: 'input',
        inputType: 'text',
        //inputValue: moment().local().format('YYYY-MM-DDTHH:mm'),
        showCancelButton: true,
        animation: 'slide-from-top',
      },
      (email) => {
        if (!email || email === '') {
          return false;
        }

        window.onkeydown = null;
        window.onfocus = null;

        doGet(`solicitations/${solicitationCode}/receipts/?_email=${email}`)
          .then((resp) => {
            toastSuccess('Email Enviado');
          })
          .catch((e) => {
            dispatch([
              {type: 'EMAIL_SENT_ERROR', payload: e},
              toastError(e.message || e.status),
            ]);
          })
          .finally(() => {
            swal.close();
          });
      },
    );
  };
}

export function selectSolicitationByCode(solicitationCode) {
  return (dispatch) => {
    dispatch({type: 'SELECTED_SOLICITATION_FETCHING', payload: true});
    doGet(`solicitations/${solicitationCode}`)
      .then((resp) => {
        dispatch([
          {type: 'SOLICITATION_LOG_UPDATED', payload: []},
          {type: 'SELECTED_SOLICITATION_FETCHED', payload: resp.solicitacao},
          {type: 'SELECTED_SOLICITATION_FETCHING', payload: false},
        ]);
      })
      .catch((e) => {
        console.log(e);
        dispatch([{type: 'SELECTED_SOLICITATION_FETCHING', payload: false}]);
      });
  };
}

const getBase64 = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64String = reader.result.replace('data:', '').replace(/^.+,/, '');
      resolve({
        ssPDF: {
          base64: base64String,
          type: 'pdf',
        },
      });
    };
    reader.onerror = (error) => reject(error);
  });
};

export function updateSolicitationPDF(solicitationCode, value, reloadFunction) {
  return async (dispatch) => {
    dispatch({type: 'SOLICITATION_VALUES_UPDATE_FETCHING', payload: true});
    doPost(`general/ssPDF/${solicitationCode}`, await getBase64(value))
      .then((resp) => {
        toastSuccess('PDF atribuido com sucesso');
        dispatch([
          {type: 'SOLICITATION_CLOSE_MODAL', payload: true},
          {type: 'SOLICITATION_VALUES_UPDATE_FETCHING', payload: false},
          {type: 'SOLICITATION_VALUES_UPDATED', payload: resp.solicitacao},
          selectSolicitationByCode(solicitationCode),
          {type: 'SOLICITATION_SELECT', solicitation: resp.solicitacao},
          {type: 'SOLICITATION_CLOSE_MODAL', payload: false},
        ]);
        reloadFunction();
      })
      .catch((e) => {
        toastError(e.mensagemCliente);
        dispatch([{type: 'SOLICITATION_VALUES_UPDATE_FETCHING', payload: false}]);
      });
  };
}

export function updateSolicitaionValues(solicitationCode, values) {
  return (dispatch) => {
    const {
      justServicePriceWithoutFee,
      justServicePriceByPartner,
      tollPrice,
      accessoryPriceWithoutFee,
      workingTimePriceWithoutFee,
      downtimePriceWithoutFee,
    } = values;
    dispatch({type: 'SOLICITATION_VALUES_UPDATE_FETCHING', payload: true});
    doPutV2(`solicitations/${solicitationCode}/values`, {
      justServicePriceWithoutFee,
      justServicePriceByPartner,
      tollPrice,
      accessoryPriceWithoutFee,
      workingTimePriceWithoutFee,
      downtimePriceWithoutFee,
    })
      .then((resp) => {
        toastSuccess('Alterado com sucesso');
        dispatch([
          {type: 'SOLICITATION_CLOSE_MODAL', payload: true},
          {type: 'SOLICITATION_VALUES_UPDATE_FETCHING', payload: false},
          {type: 'SOLICITATION_VALUES_UPDATED', payload: resp.solicitacao},
          selectSolicitationByCode(solicitationCode),
          {type: 'SOLICITATION_SELECT', solicitation: resp.solicitacao},
          {type: 'SOLICITATION_CLOSE_MODAL', payload: false},
        ]);
      })
      .catch((e) => {
        toastError(e.message);
        dispatch([{type: 'SOLICITATION_VALUES_UPDATE_FETCHING', payload: false}]);
      });
  };
}

/**
 * CompaniesContracts - tipo de contratos
 * @enum {string}
 */
export const CompaniesContracts = Object.freeze({
  FREQUENCIA: 'CONTRATOPORFREQUENCIA',
  GESTAO: 'CONTRATOPORGESTAO',
  DEMANDA: 'CONTRATOPORDEMANDA',
});

/**
 * Obtem as empresas pelo tipo de contrato
 * @param {CompaniesContracts| string} contracts - @link CompaniesContracts
 */
export function getCompanies(contracts) {
  return (dispatch) => {
    const body = {};

    if (typeof contracts === 'string') {
      body._tipoContrato = contracts;
    }

    doGet(`companies`, body)
      .then((resp) => {
        dispatch([
          {
            type: 'COMPANIES_FETCHED',
            payload: resp.listCompanies ? resp.listCompanies : [],
          },
        ]);
      })
      .catch((e) => {
        console.log(e);
      });
  };
}

export function getUsuariosRelatorios() {
  return (dispatch) => {
    doGet(`users`)
      .then((resp) => {
        dispatch([{type: 'USUARIOS_RELATORIOS_FETCHED', payload: resp.listUsers}]);
      })
      .catch((e) => {
        console.log(e);
      });
  };
}

export function getPrestadoresRelatorios(baseId) {
  return (dispatch) => {
    doGetV2(`partners?baseId=${baseId}`)
      .then((resp) => {
        dispatch([
          {
            type: 'PRESTADORES_RELATORIOS_FETCHED',
            payload: resp,
          },
        ]);
      })
      .catch((e) => {
        console.log(e);
      });
  };
}

export function getBasesRelatorios() {
  let params = {};
  params.statusIds = [74, 75].join(',');
  params.limit = 10000;
  params.page = 0;
  return (dispatch) => {
    doGetV2(`bases`, params)
      .then((resp) => {
        dispatch([
          {
            type: 'BASE_RELATORIOS_FETCHED',
            payload: resp.bases,
          },
        ]);
      })
      .catch((e) => {
        console.log(e);
      });
  };
}

export function putLog(log, codigo, visibilidade) {
  return (dispatch, getState) => {
    if (!codigo) codigo = getState().relatorio.selectedSolicitation.codigo;
    doPut(`solicitations/${codigo}/historico`, {log, visibilidade})
      .then((resp) => {
        dispatch([
          {type: 'SOLICITATION_LOG_UPDATED', payload: resp.historico},
          {type: 'SOLICITATION_LOG_FETCHED', payload: resp.historico},
        ]);
      })
      .catch((e) => {
        toastError('Erro ao enviar.');
        console.log(e);
      });
  };
}
