import toastr from 'toastr';
import {
  call,
  put,
  fork,
  take,
  takeLatest,
  takeEvery,
  select,
  all,
  cancel,
} from 'redux-saga/effects';
import {push} from 'react-router-redux';
import {isArray} from 'lodash';

import {
  translationMap,
  translateObjectKeys,
  formatDateFields,
} from '../utils/translationUtils';
import {
  acceptSolicitationResponse,
  getSolicitation,
  getPendingSolicitations,
  getScheduledSolicitations,
  getSolicitationResponse,
  monitorSolicitation,
  reopenSolicitation,
  cancelSolicitation,
  solveSolicitation,
  getInProgressSolicitations,
  getConcludedSolicitations,
  getCalledPartner,
  serviceCompleted,
  informArrival,
  callPartner,
  restartSolicitation,
  sendPushNotification,
} from '../api/solicitation_helper';
import {getClosestPartners} from '../api/partner_helper';
import {toastSuccess, toastError} from '../actions/toast_actions';
import {
  SOLICITATION_SELECT,
  CALLED_PARTINERS_CHANGED,
  startSolicitationMonitoring,
} from '../actions/followup_actions';

function* seeSolicitationOnMap(action) {
  try {
    const redirect = () => window.open(`${process.env.REACT_APP_URL}/tracking`);

    yield put(redirect());
  } catch (error) {
    console.log(error);
  }
}

function* clearSolicitationOnMap(action) {
  try {
    yield put({
      type: `REMOVE_SOLICITATION_ON_MAP`,
    });
  } catch (error) {
    console.log(error);
  }
}

function* getCalledPartners(action) {
  try {
    const id = action.value;
    const calledPartners = yield call(getCalledPartner, id);

    yield put({
      type: `CALLED_PARTINERS_CHANGED`,
      calledPartners: calledPartners.listCalledPartners
        ? calledPartners.listCalledPartners
        : [],
    });

    yield put({
      type: `CALLED_PARTNERS_SUCCESS`,
    });
  } catch (error) {
    console.log('error on getCalledPartners', error);
  }
}

function* selectSolicitation(action) {
  const id = action.value;
  try {
    const redirect = (path) => push(`/followup/${path}`);

    yield put(redirect(id));

    yield put({
      type: `STOP_LOAD_SOLICITATIONS`,
    });

    yield put({
      type: `FETCHING_SOLICITATION`,
    });

    const singleSolicitation = yield call(getSolicitation, id);

    yield put({
      type: `SOLICITATION_SELECT`,
      solicitation: singleSolicitation.solicitacao,
    });

    yield put({
      type: `SOLICITATION_SELECT_SUCCESS`,
      value: id,
    });
  } catch (error) {
    yield call(toastError, error.mensagemCliente);
    yield put({
      type: `SOLICITATION_UNSELECT`,
    });
    const redirect = () => push('/followup/');
    const id = action.value;
    yield put(redirect(id));
  }

  try {
    const calledPartners = yield call(getCalledPartner, id);

    yield put({
      type: `CALLED_PARTINERS_CHANGED`,
      calledPartners: calledPartners.listCalledPartners
        ? calledPartners.listCalledPartners
        : [],
    });

    yield put({
      type: `CALLED_PARTNERS_SUCCESS`,
    });

    yield call(startSolicitationMonitoring, singleSolicitation.solicitacao);

    yield put({
      type: `STOP_SOLICITATIONS_TIMER`,
    });
  } catch (error) {
    console.log(error);
  }
}

function* selectSolicitationWithoutClosestPartners(action) {
  try {
    const id = action.value;
    const redirect = (path) => push(`/followup/${path}`);
    const singleSolicitation = yield call(getSolicitation, id);

    yield put({
      type: `STOP_LOAD_SOLICITATIONS`,
    });

    yield put({
      type: `FETCHING_SOLICITATION`,
    });

    yield put(redirect(id));

    yield put({
      type: SOLICITATION_SELECT,
      solicitation: singleSolicitation.solicitacao,
    });

    yield put({
      type: `SOLICITATION_SELECT_SUCCESS`,
      value: id,
    });

    const calledPartners = yield call(getCalledPartner, id);

    yield put({
      type: CALLED_PARTINERS_CHANGED,
      calledPartners: calledPartners.listCalledPartners
        ? calledPartners.listCalledPartners
        : [],
    });

    yield put({
      type: `CALLED_PARTNERS_SUCCESS`,
    });

    yield call(startSolicitationMonitoring, singleSolicitation.solicitacao);

    yield put({
      type: `STOP_SOLICITATIONS_TIMER`,
    });
  } catch (error) {
    console.log('load solicitation without closest partners error', error);
  }
}

function* loadPendingSolicitations() {
  try {
    const getAllPendingSolicitations = (state) => state.followup.pendingSolicitations;
    const queryPendingSolicitations = (state) => state.followup.pendingSolicitationQuery;

    const pendingSolicitations = yield call(getPendingSolicitations);

    yield put({
      type: `PENDING_SOLICITATIONS_CHANGED`,
      solicitationList: pendingSolicitations ? pendingSolicitations : [],
    });

    const pendingFilteredSolicitations = yield select(getAllPendingSolicitations);

    yield put({
      type: `PENDING_SOLICITATIONS_FILTERED`,
      solicitationList: pendingFilteredSolicitations,
      solicitationQuery: queryPendingSolicitations,
    });
  } catch (error) {
    console.log('error loading pending solicitations saga', error);
  }
}

function* loadConcludedSolicitationsCvsExport(action) {
  try {
    const {
      startDate,
      endDate,
      empresa,
      status,
      usuario,
      veiculo,
      parceiro,
      servicos,
      bases,
      conclusaoManual,
      codigo,
      vehicleCondition,
    } = action;

    yield put({
      type: `IS_FETCHING_DASHBOARD_CVS`,
      value: true,
    });

    const exportCsv = 'true';
    const concludedSolicitations = yield call(
      getConcludedSolicitations,
      startDate,
      endDate,
      empresa,
      status,
      usuario,
      veiculo,
      parceiro,
      servicos,
      bases,
      conclusaoManual,
      codigo,
      1,
      2000,
      undefined,
      exportCsv,
      vehicleCondition,
    );

    const data = concludedSolicitations.data.map((item) =>
      translateObjectKeys(item, translationMap),
    );

    const newData = data.map((item) => {
      if (item.respostaAceita && item.respostaAceita.parceiro) {
        item.respostaAceita.parceiro.contato = item.respostaAceita.parceiro.nome;
      }
      if (item.acionamentoManual && item.acionamentoManual.descricao) {
        item.motivoConclusaoManual = {
          descricao: item.acionamentoManual.descricao,
          codigo: item.acionamentoManual.codigo,
        };
      }
      if (item.valorParceiro) item.valorParceiro = parseFloat(item.valorParceiro.toFixed(2));
      if (item.valorCorrida) item.valorCorrida = parseFloat(item.valorCorrida.toFixed(2));
      if (item.valorServico) item.valorServico = parseFloat(item.valorServico.toFixed(2));
      if (item.comissaoReboqueme)
        item.comissaoReboqueme = parseFloat(item.comissaoReboqueme.toFixed(2));
      if (item.valorHoraParada)
        item.valorHoraParada = parseFloat(item.valorHoraParada.toFixed(2));
      if (item.valorPedagio) item.valorPedagio = parseFloat(item.valorPedagio.toFixed(2));
      if (item.valorServicoParceiro)
        item.valorServicoParceiro = parseFloat(item.valorServicoParceiro.toFixed(2));
      return formatDateFields(item);
    });
    const pages = concludedSolicitations.meta.pagination.totalPages;

    if (data) {
      yield put({
        type: `CONCLUDED_SOLICITATIONS_CVS_PROGRESS`,
        progress: 1,
      });

      if (pages && pages > 1) {
        const allSolicitations = newData;
        for (let i = 2; i <= pages; i++) {
          const exportCsv = 'false';
          const solicitations = yield call(
            getConcludedSolicitations,
            startDate,
            endDate,
            empresa,
            status,
            usuario,
            veiculo,
            parceiro,
            servicos,
            conclusaoManual,
            codigo,
            i,
            currentPageSize,
            exportCsv,
          );
          const solicitationInfo = solicitations.data;
          const formattedSolicitations = solicitationInfo.map((item) =>
            formatDateFields(item),
          );

          const solicitationsData = formattedSolicitations.map((item) =>
            translateObjectKeys(item, translationMap),
          );

          if (solicitationInfo) {
            yield put({
              type: `CONCLUDED_SOLICITATIONS_CVS_PROGRESS`,
              progress: i,
            });
            allSolicitations.push(...solicitationsData);
          }
        }

        yield put({
          type: `CONCLUDED_SOLICITATIONS_CVS_CHANGED`,
          solicitationList: allSolicitations ? allSolicitations : [],
        });

        yield put({
          type: `CONCLUDED_SOLICITATIONS_CVS_PROGRESS`,
          progress: 0,
        });
      } else {
        yield put({
          type: `CONCLUDED_SOLICITATIONS_CVS_CHANGED`,
          solicitationList: newData ? newData : [],
        });
        yield put({
          type: `CONCLUDED_SOLICITATIONS_CVS_PROGRESS`,
          progress: 0,
        });
      }
    }

    yield put({
      type: `IS_FETCHING_DASHBOARD_CVS`,
      value: false,
    });
  } catch (error) {
    console.log('error loading concluded solicitations saga', error);
    if (error.mensagemRetorno) toastError(error.mensagemRetorno);
    else toastError('Não é possível exportar mais de 5000 solicitações.');
    yield put({
      type: `IS_FETCHING_DASHBOARD_CVS`,
      value: false,
    });
    yield put({
      type: `CONCLUDED_SOLICITATIONS_CVS_PROGRESS`,
      progress: 0,
    });
  }
}

function* loadConcludedSolicitations(action) {
  try {
    const getAllConcludedSolicitations = (state) => state.dashboard.concludedSolicitations;
    const queryConcludedSolicitations = (state) => state.dashboard.concludedSolicitationQuery;
    const {
      startDate,
      endDate,
      sizePerPage,
      empresa,
      status,
      usuario,
      veiculo,
      parceiro,
      servicos,
      bases,
      conclusaoManual,
      codigo,
      page,
      ownId,
      vehicleCondition,
      paymentAdvance,
      pendingInvoice,
    } = action;
    yield put({
      type: `IS_FETCHING_DASHBOARD`,
      value: true,
    });

    const exportCsv = 'false';
    const concludedSolicitations = yield call(
      getConcludedSolicitations,
      startDate,
      endDate,
      empresa,
      status,
      usuario,
      veiculo,
      parceiro,
      servicos,
      bases,
      conclusaoManual,
      codigo,
      page,
      sizePerPage,
      ownId,
      exportCsv,
      vehicleCondition,
      paymentAdvance,
      pendingInvoice,
    );

    const data = concludedSolicitations.data.map((item) =>
      translateObjectKeys(item, translationMap),
    );

    const newData = data.map((item) => {
      if (item.respostaAceita && item.respostaAceita.parceiro) {
        item.respostaAceita.parceiro.contato = item.respostaAceita.parceiro.nome;
      }
      item.valorParceiro = Number((item.valorParceiro || 0).toFixed(2));
      item.valorCorrida = Number((item.valorCorrida || 0).toFixed(2));
      item.valorServico = Number((item.valorServico || 0).toFixed(2));
      item.comissaoReboqueme = Number((item.comissaoReboqueme || 0).toFixed(2));
      item.valorHoraParada = Number((item.valorHoraParada || 0).toFixed(2));
      item.valorPedagio = Number((item.valorPedagio || 0).toFixed(2));
      item.valorServicoParceiro = Number((item.valorServicoParceiro || 0).toFixed(2));
      return formatDateFields(item);
    });

    yield put({
      type: `CONCLUDED_SOLICITATIONS_CHANGED`,
      solicitationList: newData ? newData : [],
      totalItems: concludedSolicitations.meta.pagination.totalItems,
      pages: concludedSolicitations.meta.pagination.totalPages,
    });

    const concludedFilteredSolicitations = yield select(getAllConcludedSolicitations);

    yield put({
      type: `CONCLUDED_SOLICITATIONS_FILTERED`,
      solicitationList: concludedFilteredSolicitations,
      solicitationQuery: queryConcludedSolicitations,
    });

    yield put({
      type: `IS_FETCHING_DASHBOARD`,
      value: false,
    });
  } catch (error) {
    console.log('error loading concluded solicitations saga', error);
    if (error.mensagemRetorno) toastError(error.mensagemRetorno);
    yield put({
      type: `IS_FETCHING_DASHBOARD`,
      value: false,
    });
  }
}

function* loadScheduledSolicitations() {
  try {
    const getAllScheduledSolicitations = (state) => state.followup.scheduledSolicitations;
    const queryScheduledSolicitations = (state) => state.followup.scheduledSolicitationQuery;
    const scheduledSolicitations = yield call(getScheduledSolicitations);

    yield put({
      type: `SCHEDULED_SOLICITATIONS_CHANGED`,
      solicitationList: scheduledSolicitations.listSolicitations
        ? scheduledSolicitations.listSolicitations
        : [],
    });

    const scheduledFilteredSolicitations = yield select(getAllScheduledSolicitations);

    yield put({
      type: `SCHEDULED_SOLICITATIONS_FILTERED`,
      solicitationList: scheduledFilteredSolicitations,
      solicitationQuery: queryScheduledSolicitations,
    });
  } catch (error) {
    console.log('error loading scheduled solicitations saga', error);
  }
}

function* stopAllTimers() {
  const timerAguardandoAceite = (state) => state.timer[`AGUARDANDOACEITE`];
  const timerEmAtendimento = (state) => state.timer[`EMATENDIMENTO`];
  const timerSolicitacoes = (state) => state.timer[`SOLICITACOES`];

  const idTimerSolicitacoes = yield select(timerSolicitacoes);
  const idTimerAguardandoAceite = yield select(timerAguardandoAceite);
  const idTimerEmAtendimento = yield select(timerEmAtendimento);

  clearInterval(idTimerAguardandoAceite);
  clearInterval(idTimerEmAtendimento);
  clearInterval(idTimerSolicitacoes);

  yield put({
    type: `STOP_LOAD_SOLICITATIONS`,
  });

  yield put({
    type: `TIMER_STOP`,
    id: `AGUARDANDOACEITE`,
    reference: timerAguardandoAceite,
  });
  yield put({
    type: `TIMER_STOP`,
    id: `EMATENDIMENTO`,
    reference: timerEmAtendimento,
  });
  yield put({
    type: `TIMER_STOP`,
    id: `SOLICITACOES`,
    reference: timerSolicitacoes,
  });
}

function* stopSolicitationsTimer() {
  const timerSolicitacoes = (state) => state.timer[`SOLICITACOES`];
  const idSolicitacoes = yield select(timerSolicitacoes);
  clearInterval(idSolicitacoes);

  yield put({
    type: `STOP_LOAD_SOLICITATIONS`,
  });

  yield put({
    type: `TIMER_STOP`,
    id: `SOLICITACOES`,
  });
}

function* handleCallPartner(action) {
  try {
    const {partner, solicitation} = action;
    const {codigo} = partner.value;
    const distance = partner.distance * 1000;
    const callAPartner = yield call(callPartner, solicitation, codigo, distance);

    yield put({
      type: `STOP_LOAD_SOLICITATIONS`,
    });

    yield put({
      type: `CALL_PARTNER_SUCCESS`,
      value: solicitation,
    });

    yield put({
      type: `REMOVE_CLOSEST_PARTNERS`,
      value: codigo,
    });

    yield call(toastSuccess, callAPartner.mensagemCliente);
  } catch (error) {
    yield call(toastError, error.mensagemCliente);
  }
}
function* handlePushNotificationPartner(action) {
  try {
    const {partnerCode, message} = action;
    const pushNotificationToPartner = yield call(sendPushNotification, partnerCode, message);

    yield call(toastSuccess, pushNotificationToPartner.mensagemRetorno);
  } catch (error) {
    yield call(toastError, error.mensagemCliente);
  }
}

function* onRestartSolicitation(action) {
  try {
    const codigo = action.value;
    const restartedSolicitation = yield call(restartSolicitation, codigo);

    yield put({
      type: `STOP_LOAD_SOLICITATIONS`,
    });

    yield put({
      type: `RESTART_SOLICITATION_SUCCESS`,
    });
    yield call(toastSuccess, restartedSolicitation.mensagemCliente);
  } catch (error) {
    console.log('ERROR ON RESTART SOLICITATION', error);
    yield call(toastError, error.mensagemCliente);
  }
}

function* getClosestPartnersSaga(action) {
  try {
    yield put({
      type: `LOADING_CLOSEST_PARTNERS`,
      value: true,
    });
    const id = action.value;
    const closestPartners = yield call(getClosestPartners, id);

    yield put({
      type: `GET_CLOSEST_PARTNERS`,
      value: closestPartners.partners,
    });

    yield put({
      type: `LOADING_CLOSEST_PARTNERS`,
      value: false,
    });
  } catch (error) {
    if (error.mensagemRetorno === `Nenhum parceiro próximo desta solicitação.`) {
      yield put({
        type: `GET_CLOSEST_PARTNERS`,
        value: [],
      });
    }
  }
}

function* getClosestPhantomPartnersSaga(action) {
  try {
    yield put({
      type: `LOADING_CLOSEST_PARTNERS`,
      value: true,
    });
    const {id, fantasmas} = action.value;

    const closestPartners = yield call(getClosestPartners, id, fantasmas);

    yield put({
      type: `GET_CLOSEST_PARTNERS`,
      value: closestPartners.partners,
    });

    yield put({
      type: `LOADING_CLOSEST_PARTNERS`,
      value: false,
    });
  } catch (error) {
    if (error.mensagemRetorno === `Nenhum parceiro próximo desta solicitação.`) {
      yield put({
        type: `GET_CLOSEST_PARTNERS`,
        value: [],
      });
    }
  }
}

function* clearClosestPartnersSaga() {
  try {
    yield put({
      type: `GET_CLOSEST_PARTNERS`,
      value: [],
    });
  } catch (error) {
    console.log(error);
  }
}

function* stopSolicitationsLoading() {
  const syncPending = yield fork(watchPendingSolicitations);
  const syncConcluded = yield fork(watchConcludedSolicitations);
  const syncInProgress = yield fork(watchInProgressSolicitations);

  yield cancel(syncPending);
  yield cancel(syncConcluded);
  yield cancel(syncInProgress);
}

const stagesAllowed = new Set(['Atendimento Confirmado', 'Atendimento Concluido']);

const statesAllowed = new Set(['STATUSATENDIMENTOFECHADO']);

function enableConclusionTab(item) {
  return stagesAllowed.has(item.estagio);
}

function* handleConclusionTabVisibility(action) {
  if (action.payload) {
    const {payload} = action;
    let enabled = false;

    const extractStatus = (state) => state.attendance.attendanceTab.status;

    const status = yield select(extractStatus);
    if (status && statesAllowed.has(status.codigo)) {
      yield put({type: 'SET_CONCLUSION_TAB_VISIBILITY', payload: true});
      return;
    }

    if (payload.historico) {
      enabled = payload.historico.some(enableConclusionTab);
    }

    yield put({type: 'SET_CONCLUSION_TAB_VISIBILITY', payload: enabled});
  }
}

function* handleNewHistoryConclusionTabVibility(action) {
  if (action.payload) {
    let enabled = false;
    const {payload} = action;

    const extractStatus = (state) => state.attendance.attendanceTab.status;

    const status = yield select(extractStatus);
    if (status && statesAllowed.has(status.codigo)) {
      yield put({type: 'SET_CONCLUSION_TAB_VISIBILITY', payload: true});
      return;
    }

    if (isArray(payload)) {
      enabled = payload.some(enableConclusionTab);
    }

    yield put({type: 'SET_CONCLUSION_TAB_VISIBILITY', payload: enabled});
  }
}

function* watchAttendancesHistoryChanged() {
  yield takeEvery(`ATTENDANCES_HISTORY_CHANGED`, handleNewHistoryConclusionTabVibility);
}

function* watchLoadAttendanceTab() {
  yield takeEvery(`LOAD_ATTENDANCE_TAB`, handleConclusionTabVisibility);
}

function* watchSelectSolicitation() {
  yield takeLatest(`FOLLOWUP_SINGLE_SOLICITATION`, selectSolicitation);
}
function* watchPendingSolicitations() {
  yield takeLatest(`LOAD_PENDING_SOLICITATION`, loadPendingSolicitations);
}
function* watchConcludedSolicitations() {
  yield takeLatest(`LOAD_CONCLUDED_SOLICITATION`, loadConcludedSolicitations);
}
function* watchConcludedSolicitationsCVSExport() {
  yield takeLatest(
    `LOAD_CONCLUDED_SOLICITATION_CVS_EXPORT`,
    loadConcludedSolicitationsCvsExport,
  );
}
function* watchScheduledSolicitations() {
  yield takeLatest(`LOAD_SCHEDULED_SOLICITATION`, loadScheduledSolicitations);
}
function* watchStopAllTimers() {
  yield takeLatest(`STOP_ALL_TIMERS`, stopAllTimers);
  yield takeLatest(`STOP_SOLICITATIONS_TIMER`, stopSolicitationsTimer);
}
function* watchErrorCalledPartners() {
  yield takeLatest(`ERROR_CLOSEST_PARTNERS`, selectSolicitationWithoutClosestPartners);
}
function* watchHandleCallPartner() {
  yield takeLatest(`HANDLE_CALL_PARTNER`, handleCallPartner);
}
function* watchHandlePushNotificationPartner() {
  yield takeLatest(`HANDLE_PUSH_NOTIFICATION_PARTNER`, handlePushNotificationPartner);
}
function* watchGetCalledPartners() {
  yield takeLatest(`CALL_PARTNER_SUCCESS`, getCalledPartners);
  yield takeLatest(`GET_CALLED_PARTNERS`, getCalledPartners);
}

function* watchOnRestartSolicitation() {
  yield takeLatest(`RESTART_SOLICITATION`, onRestartSolicitation);
}

function* watchStopSolicitationsLoading() {
  yield takeLatest(`STOP_SOLICITATIONS_LOADING`, stopSolicitationsLoading);
}

function* watchSeeSolicitationOnmap() {
  yield takeLatest(`SEE_SOLICITATION_ON_MAP`, seeSolicitationOnMap);
}

function* watchClearSolicitationOnMap() {
  yield takeLatest(`CLEAR_SOLICITATION_TO_PIN`, clearSolicitationOnMap);
}

function* watchGetClosestPartnersSaga() {
  yield takeLatest(`HANDLE_CLOSEST_PARTNERS`, getClosestPartnersSaga);
}

function* watchClearClosestPartnersSaga() {
  yield takeLatest(`CLEAR_CLOSEST_PARTNERS`, clearClosestPartnersSaga);
}

function* watchGetClosestPhantomPartnesSaga() {
  yield takeLatest(`HANDLE_CLOSEST_PHANTOM_PARTNERS`, getClosestPhantomPartnersSaga);
}

export default function* rootSaga() {
  yield all([
    watchSelectSolicitation(),
    watchPendingSolicitations(),
    watchConcludedSolicitations(),
    watchStopAllTimers(),
    watchErrorCalledPartners(),
    watchHandleCallPartner(),
    watchHandlePushNotificationPartner(),
    watchGetCalledPartners(),
    watchOnRestartSolicitation(),
    watchStopSolicitationsLoading(),
    watchSeeSolicitationOnmap(),
    watchClearSolicitationOnMap(),
    watchGetClosestPartnersSaga(),
    watchClearClosestPartnersSaga(),
    watchGetClosestPhantomPartnesSaga(),
    watchLoadAttendanceTab(),
    watchAttendancesHistoryChanged(),
    watchConcludedSolicitationsCVSExport(),
  ]);
}
