/* global google */
import React, {Component} from 'react';

import ReactPlaceholder from 'react-placeholder';
import 'react-placeholder/lib/reactPlaceholder.css';

import _ from 'underscore';

import Card from '../components/common/Card';
// import { sendMapNotification } from "/../api/heatMapHelper";

import SimpleMap from '../components/common/GoogleMaps';

import FollowUpTabs from '../components/followup/FollowUpTabs';
import FollowUpItemDetails from '../components/followup/FollowUpItemDetails';
import FollowUpPendingDetails from '../components/followup/FollowUpPendingDetails';
import FollowUpScheduledDetails from '../components/followup/FollowUpScheduledDetails';
import {
  onSolicitationSelect,
  onSolicitationUnselect,
  onSolicitationSolve,
  onAcceptSolicitationResponse,
  loadPendingSolicitationsList,
  onSolicitationFilter,
  onSolicitationReopen,
  onSolicitationCancel,
  onSolicitationSolutionChanged,
  stopAllTimers,
  loadCalledPartnersList,
  loadInProgressSolicitationsList,
  loadScheduledSolicitationsList,
  onServiceCompleted,
  onInformArrival,
  openWhatsApp,
  onResponsavelAcionamentoUpdate,
  onResponsavelAcionamentoRemove,
  onResponsavelMonitoramentoUpdate,
  sendPartnerWebappSMS,
  sendPartnerWebappEmail,
  sendPartnerWebappChat,
  onResponsavelMonitoramentoRemove,
  onUpdatePrevia,
  copyWebAppLinkToClipboard,
} from '../actions/followup_actions';
import CustomModal, {doOpenModal, doCloseModal} from '../components/common/CustomModal';

import ChecklistModal from '../components/checklist/ChecklistModal';
import {putLog} from '../actions/relatorio_actions';
import {connect} from 'react-redux';
import {confirmDialog, confirmDialogCustomized} from '../actions/confirm_actions';
import {toastSuccess, toastWarning} from '../actions/toast_actions';
import {
  sendPushNotification,
  addExpirationTime,
  removeNotifyPartner,
  acceptSolicitationPartner,
  expireSolicitation as actExpireSolicitation,
  startScheduledSolicitation,
} from '../actions/solicitations_actions';
import beep from '../vendor/sounds/beep.wav';
import {browserHistory, withRouter} from 'react-router';
import swal from 'sweetalert';
import RelatoriosLog from '../components/common/RelatoriosLog';
import EditInfo from '../components/followup/EditInfo';
import FileUploader from '../components/common/fileUploader/FileUploader';
import CheckListUpload from '../components/common/checkListUpload/CheckListUpload';
import AlterDestination from '../components/followup/followUpItemButton/alterDestination.jsx';
import {acceptSolicitation} from '../api/solicitation_helper';
import {
  HISTORICO_MODO_PRIVADO,
  HISTORICO_MODO_PUBLICO,
  USUARIO_TIPO_EMPRESA,
  USUARIO_TIPO_REBOQUEME,
} from '../reducers/constants/index';
import {
  hasAccessToPublicHistoryPost,
  hasManagerAcess,
  hasReboqueMeProfile,
} from '../utils/check_permission';

const placeHolder = (
  <div className="ibox-content">
    <div className="spiner-example">
      <div className="sk-spinner sk-spinner-double-bounce">
        <div className="sk-double-bounce1" />
        <div className="sk-double-bounce2" />
      </div>
    </div>
  </div>
);

class FollowUpView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabSelected: 1,
      loadedMap: false,
      log: '',
      modalContext: 'CONTEXT_FOLLOWUP',
      visibility: HISTORICO_MODO_PUBLICO,
      intervalId: null,
    };
    this.audio = new Audio(beep);
    this.sendNotification = this.sendNotification.bind(this);
    this.playSound = this.playSound.bind(this);
    this.handleLog = this.handleLog.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.prePutLog = this.prePutLog.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.updateSolicitationsTimer = this.updateSolicitationsTimer.bind(this);
    this.refreshPendingSolitication = this.refreshPendingSolitication.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const {pendingSolicitations} = this.props;
    if (pendingSolicitations.length < nextProps.pendingSolicitations.length) {
      this.sendNotification('Nova solicitação pendente!');
    } else if (pendingSolicitations.length === nextProps.pendingSolicitations.length) {
      for (let p of pendingSolicitations) {
        for (let np of nextProps.pendingSolicitations) {
          if (p.codigo === np.codigo && p.statusSolicitacao !== np.statusSolicitacao)
            this.sendNotification('text');
          return;
        }
      }
    }

    if (
      nextProps.solicitation &&
      this.props.solicitation &&
      nextProps.solicitation.codigo !== this.props.solicitation.codigo
    ) {
      this.props.clearClosestPartners();
    }
  }
  prePutLog() {
    this.props.putLogEx(this.state.log, this.state.visibility);
    this.setState({log: ''});
  }
  showWarning() {
    if (this.props.login.user_info.usuario.tipoUsuario === 1) {
      toastWarning(
        'Atenção: Em breve a aba "Pendentes" será totalmente substituída pela nova tela de Acionamento',
      );
    }
  }

  componentDidMount() {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.showEmAndamento
    ) {
      this.setState({tabSelected: 2});
      this.props.loadInProgressSolicitations();
    }
    const intervalId = setInterval(this.updateSolicitationsTimer, 60000);
    this.setState({intervalId: intervalId});
    const {
      params,
      onItemSelect,
      loadPendingSolicitations,
      clearDetails,
      loadScheduledSolicitations,
    } = this.props;
    if (params && params.id) {
      onItemSelect({currentTarget: {id: params.id}});
      loadPendingSolicitations();
      loadScheduledSolicitations();
    } else {
      clearDetails();
    }

    if (hasReboqueMeProfile() && !hasAccessToPublicHistoryPost()) {
      this.setState({
        ...this.state,
        visibility: HISTORICO_MODO_PRIVADO,
      });
    }
    Notification.requestPermission();
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  updateSolicitationsTimer() {
    const {
      loadScheduledSolicitations,
      // loadPendingSolicitations,
      loadInProgressSolicitations,
    } = this.props;
    const {tabSelected} = this.state;
    if (tabSelected === 0) {
      loadScheduledSolicitations();
    } else if (tabSelected === 1) {
      // loadPendingSolicitations();
    }
  }

  refreshPendingSolitication() {
    const {
      params,
      onItemSelect,
      loadPendingSolicitations,
      loadScheduledSolicitations,
    } = this.props;

    onItemSelect({currentTarget: {id: params.id}});
    loadPendingSolicitations();

    loadScheduledSolicitations();
  }

  handleLog(e) {
    this.setState({log: e.target.value});
  }

  onChangeVisibility = (event) => {
    this.setState({
      ...this.state,
      visibility: event.target.value,
    });
  };

  handleKeyUp(e) {
    const {putLogEx} = this.props;
    const {log} = this.state;
    if (e.key === 'Enter' && e.ctrlKey) {
      putLogEx(log, this.state.visibility);
      this.setState({log: ''});
    }
  }

  sendNotification = (text) => {
    this.playSound();
  };

  handleModal(context) {
    doOpenModal(context);
  }

  playSound() {
    this.audio.play();
  }

  createSolicitationMarkers = (solicitation = {}) => {
    const {enderecoOrigem, enderecoDestino, respostaAceita} = solicitation;
    let markers = [];
    if (solicitation && enderecoOrigem) {
      markers.requestOrigin = {
        lat: enderecoOrigem.latitude,
        lng: enderecoOrigem.longitude,
        logradouro: enderecoOrigem.logradouro,
      };

      if (solicitation && enderecoDestino) {
        markers.requestDestination = {
          lat: enderecoDestino.latitude,
          lng: enderecoDestino.longitude,
          logradouro: enderecoDestino.logradouro,
        };
      }

      if (solicitation && respostaAceita && respostaAceita.parceiro) {
        markers.truck = {
          lat: respostaAceita.parceiro.latitude,
          lng: respostaAceita.parceiro.longitude,
          bearing: respostaAceita.parceiro.bearing,
        };
      }

      if (
        hasReboqueMeProfile() &&
        solicitation &&
        respostaAceita &&
        respostaAceita.latitude &&
        respostaAceita.longitude
      ) {
        markers.agreeTravelAt = {
          lat: respostaAceita.latitude,
          lng: respostaAceita.longitude,
        };
      }
    }

    return markers;
  };

  createSolicitationsListMarkers = (solicitationList = []) => {
    return solicitationList.map(({enderecoOrigem, ajudaNecessaria}) => {
      return {
        lat: enderecoOrigem.latitude,
        lng: enderecoOrigem.longitude,
        logradouro: enderecoOrigem.logradouro,
        ajudaNecessaria,
      };
    });
  };

  createMarkers = (
    solicitation,
    pendingSolicitations,
    inProgressSolicitations,
    scheduledSolicitations,
  ) => {
    const {tabSelected} = this.state;
    if (solicitation) {
      return this.createSolicitationMarkers(solicitation);
    } else if (tabSelected === 1) {
      return {
        markList: this.createSolicitationsListMarkers(pendingSolicitations),
      };
    } else if (tabSelected === 0) {
      return {
        markList: this.createSolicitationsListMarkers(scheduledSolicitations),
      };
    } else {
      return {
        markList: this.createSolicitationsListMarkers(inProgressSolicitations),
      };
    }
  };

  handleTab = (index) => {
    if (this.state.tabSelected !== index) {
      this.props.clearDetails();
    }
    this.setState({tabSelected: index, loadedMap: false}, () => {
      if (this.state.tabSelected === 0) {
        this.props.loadScheduledSolicitations();
      } else if (this.state.tabSelected === 2) {
        this.props.loadInProgressSolicitations();
      }
    });
  };

  render() {
    const {
      containerHeight,
      onFilter,
      pendingSolicitations,
      inProgressSolicitations,
      scheduledSolicitations,
      onItemSelect,
      solicitation,
      notifyPartner,
      removeNotify,
      clearDetails,
      onCancel,
      updatePrevia,
      onCancelCustomized,
      onReopen,
      onAccept,
      onSolve,
      onSelectReason,
      partner,
      response,
      calledPartners,
      extendSolicitationTime,
      expireSolicitation,
      serviceCompleted,
      informArrival,
      isFetchingSolicitation,
      loadScheduledSolicitations,
      loadInProgressSolicitations,
      loadPendingSolicitations,
      acceptPartnerSolicitation,
      propsLogList,
      newDestination,
    } = this.props;
    const markers = this.createMarkers(
      solicitation,
      pendingSolicitations,
      inProgressSolicitations,
      scheduledSolicitations,
    );
    let historico = [];
    if (solicitation && solicitation.historico) historico = solicitation.historico;

    let leftPanel = (
      <FollowUpTabs
        handleTab={this.handleTab}
        tabSelected={this.state.tabSelected}
        height={containerHeight}
        onFilter={onFilter}
        pendingSolicitations={pendingSolicitations}
        inProgressSolicitations={inProgressSolicitations}
        scheduledSolicitations={scheduledSolicitations}
        onItemSelect={onItemSelect}
        solicitationCode={solicitation}
        loadScheduledSolicitations={loadScheduledSolicitations}
        loadInProgressSolicitations={loadInProgressSolicitations}
        loadPendingSolicitations={loadPendingSolicitations}
        login={this.props.login}
        history={this.props.history}
        location={this.props.location}
      />
    );
    if (solicitation && solicitation.statusSolicitacao === 'Em Atendimento')
      leftPanel = (
        <FollowUpItemDetails
          height={containerHeight}
          onServiceCompleted={serviceCompleted}
          calledPartners={calledPartners}
          onInformArrival={informArrival}
          onClose={clearDetails}
          solicitation={solicitation}
          onCancel={onCancelCustomized}
          onReopen={onReopen}
          onAccept={onAccept}
          onSolve={onSolve}
          updatePrevia={updatePrevia}
          login={this.props.login}
          sendPartnerWebappSMS={this.props.sendPartnerWebappSMS}
          sendPartnerWebappChat={this.props.sendPartnerWebappChat}
          sendPartnerWebappEmail={this.props.sendPartnerWebappEmail}
          copyWebAppLinkToClipboard={this.props.copyWebAppLinkToClipboard}
          onResponsavelUpdate={this.props.onResponsavelMonitoramentoUpdate}
          onResponsavelRemove={this.props.onResponsavelMonitoramentoRemove}
          onSelectReason={onSelectReason}
          partner={partner}
          response={response}
          onRestartSolicitation={this.props.onRestartSolicitation}
          openWpp={this.props.openWpp}
          openModal={this.handleModal}
          profileId={this.props.login.user_info.usuario.tipoUsuario}
        />
      );

    let rightPanel = (
      <SimpleMap
        style={{height: containerHeight, width: '100%'}}
        requestOrigin={markers.requestOrigin}
        requestDestination={markers.requestDestination}
        partnerLocation={markers.truck}
        agreeTravelAt={markers.agreeTravelAt}
        markList={markers.markList}
        zoom={11}
        calledPartners={calledPartners}
        solicitation={solicitation}
        seeOnMap={this.props.seeOnMap}
      />
    );
    if (
      hasReboqueMeProfile() &&
      !hasManagerAcess() &&
      solicitation &&
      !solicitation.usuarioResponsavelMonitoramento
    ) {
      rightPanel = (
        <div
          style={{
            display: 'grid',
            placeItems: 'center',
            height: '100vh',
            fontSize: 32,
            fontWeight: 'bold',
          }}>
          <p style={{marginBottom: '30vh', width: '70%', textAlign: 'center'}}>
            Se vincule como responsável para visualizar o mapa!
          </p>
        </div>
      );
    }

    if (solicitation && solicitation.statusSolicitacao !== 'Em Atendimento')
      rightPanel = (
        <FollowUpPendingDetails
          login={this.props.login}
          openModal={this.handleModal}
          openWpp={this.props.openWpp}
          height={containerHeight}
          onFilter={onFilter}
          calledPartners={calledPartners}
          onItemSelect={onItemSelect}
          onClose={clearDetails}
          solicitation={solicitation}
          onCancel={onCancel}
          onCancelCustomized={onCancelCustomized}
          onReopen={onReopen}
          onAccept={onAccept}
          onSolve={onSolve}
          onSelectReason={onSelectReason}
          onResponsavelUpdate={this.props.onResponsavelAcionamentoUpdate}
          onResponsavelRemove={this.props.onResponsavelAcionamentoRemove}
          partner={partner}
          response={response}
          notifyPartner={notifyPartner}
          acceptSolicitation={acceptPartnerSolicitation}
          partnerLoading={this.props.partnerLoading}
          removeNotify={removeNotify}
          extendSolicitationTime={extendSolicitationTime}
          startScheduledSolicitation={this.props.startScheduledSolicitation}
          expireSolicitation={expireSolicitation}
          loadCalledPartners={this.props.loadCalledPartners}
          handleClosestPartners={this.props.handleClosestPartners}
          closestPartners={this.props.closestPartners}
          isFetchingSolicitation={this.props.isFetchingSolicitation}
          isFetchingCalledPartners={this.props.isFetchingCalledPartners}
          seeOnMap={this.props.seeOnMap}
          clearClosestPartners={this.props.clearClosestPartners}
          profileId={this.props.login.user_info.usuario.tipoUsuario}
        />
      );

    if (isFetchingSolicitation === true) {
      rightPanel = (
        <div className="full-height-scroll animated fadeIn">
          <Card title={`Solicitação: `} contentClassName="no-padding" onClose={clearDetails}>
            <ReactPlaceholder
              type="text"
              rows={15}
              ready={!this.props.isFetchingSolicitation}
              customPlaceholder={placeHolder}
              showLoadingAnimation>
              <ul
                className="list-group"
                style={{
                  height: containerHeight - 50 + 'px',
                  overflow: 'auto',
                }}>
                <li className="list-group-item">
                  <div style={{backgroundColor: 'white'}}>
                    <div className="row">
                      <div className="col-md-6 col-md-5 col-sm-7 col-xs-12">
                        <div className="row" />
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </ReactPlaceholder>
          </Card>
        </div>
      );
    }
    if (solicitation && solicitation.statusSolicitacao === 'Concluída')
      rightPanel = (
        <FollowUpScheduledDetails
          height={containerHeight}
          onFilter={onFilter}
          calledPartners={calledPartners}
          onItemSelect={onItemSelect}
          onClose={clearDetails}
          solicitation={solicitation}
          onCancel={onCancel}
          onReopen={onReopen}
          onAccept={onAccept}
          onSolve={onSolve}
          onSelectReason={onSelectReason}
          partner={partner}
          response={response}
          notifyPartner={notifyPartner}
          removeNotify={removeNotify}
          extendSolicitationTime={extendSolicitationTime}
          loadCalledPartners={this.props.loadCalledPartners}
          isFetchingSolicitation={this.props.isFetchingSolicitation}
          login={this.props.login}
        />
      );

    return (
      <div className="wrapper wrapper-content">
        <CustomModal context={'ALTER_DESTINATION'} title="Alterar Destino">
          {solicitation ? (
            <AlterDestination
              newDestination={newDestination}
              service={solicitation}
              context={'ALTER_DESTINATION'}
              handleRefresh={this.refreshPendingSolitication}
              handleClose={(context) => doCloseModal(context)}
              valueActual={solicitation.valorCorrida}
              destinationActual={solicitation.enderecoDestino}
            />
          ) : null}
        </CustomModal>

        {solicitation ? (
          <ChecklistModal
            context="CHECKLIST_MODAL"
            title="Checklist"
            solicitationCode={solicitation.codigo}
          />
        ) : null}

        <CustomModal context={'CONTEXT_FOLLOWUP'} title="Histórico">
          <RelatoriosLog
            logList={propsLogList.length > 0 ? propsLogList : historico}
            currentEstagio={{}}
            handleLog={this.handleLog}
            log={this.state.log}
            putLog={this.prePutLog}
            onKeyUp={this.handleKeyUp}
            visibility={this.state.visibility}
            onChangeVisibility={this.onChangeVisibility}
            profileId={this.props.login.user_info.usuario.tipoUsuario}
          />
        </CustomModal>
        <CustomModal context={'CONTEXT_EDITINFO'} title="Alterar Informações">
          <EditInfo solicitation={solicitation} />
        </CustomModal>
        <CustomModal context={'CONTEXT_ADDIMG'} customClass="big-modal">
          <div className="col-md-12">
            {solicitation ? (
              <CheckListUpload
                solicitationCode={solicitation.codigo}
                context={'CONTEXT_ADDIMG'}
              />
            ) : null}
          </div>
        </CustomModal>
        <div className="row">
          <div className="col-md-4 col-md-5 col-sm-7 col-xs-12">{leftPanel}</div>
          <div className="col-md-8 col-md-7 col-sm-5 col-xs-12">{rightPanel}</div>
        </div>
        <audio id="sound" preload="auto">
          <source
            src="https://s3-sa-east-1.amazonaws.com/files.reboque.me/sounds/sound.mp3"
            type="audio/mpeg"
          />
          <source
            src="https://s3-sa-east-1.amazonaws.com/files.reboque.me/sounds/sound.ogg"
            type="audio/ogg"
          />
        </audio>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    newDestination: state.service.enderecoDestino,
    propsLogList: state.followup.log,
    containerHeight: state.window.innerHeight - 145,
    solicitation: state.followup.currentSolicitation,
    response: state.followup.currentResponse,
    partner: state.followup.partner,
    partnerLoading: state.partner.isLoading,
    calledPartners: state.followup.calledPartners,
    isFetchingSolicitation: state.followup.isFetchingSolicitation,
    isFetchingCalledPartners: state.followup.isFetchingCalledPartners,
    pendingSolicitations: state.followup.filteredPendingSolicitations
      ? state.followup.filteredPendingSolicitations
      : [],
    inProgressSolicitations: state.followup.filteredInProgressSolicitations
      ? state.followup.filteredInProgressSolicitations
      : [],
    scheduledSolicitations: state.followup.filteredScheduledSolicitations
      ? state.followup.filteredScheduledSolicitations
      : [],
    login: state.login,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    putLogEx: (log, visibility) => {
      dispatch(putLog(log, ownProps.params.id, visibility));
    },
    openWpp: (number, msg) => {
      dispatch(openWhatsApp(number, msg));
    },
    serviceCompleted: () => {
      dispatch(onServiceCompleted());
    },
    updateResponsavel: () => {
      dispatch(onServiceCompleted());
    },
    updatePrevia: () => {
      dispatch([onUpdatePrevia()]);
    },
    informArrival: () => {
      dispatch([onInformArrival()]);
    },
    extendSolicitationTime: (time) => {
      dispatch(addExpirationTime(time));
    },
    expireSolicitation: (time) => {
      dispatch(actExpireSolicitation());
    },
    notifyPartner: (codePartner) => {
      dispatch(sendPushNotification(codePartner));
    },
    loadCalledPartners: (solicitationCode) => {
      dispatch(loadCalledPartnersList(solicitationCode));
    },
    acceptPartnerSolicitation: (solicitationCode, codePartner) => {
      dispatch(acceptSolicitationPartner(solicitationCode, codePartner));
    },
    startScheduledSolicitation: (solicitationCode) => {
      dispatch(startScheduledSolicitation(solicitationCode));
    },
    removeNotify: (solicitationCode, codePartner) => {
      setTimeout(
        dispatch({
          type: `GET_CALLED_PARTNERS`,
          value: solicitationCode,
        }),
        2000,
      );
      dispatch(removeNotifyPartner(solicitationCode, codePartner));
      //dispatch( loadCalledPartnersList( solicitationCode ) )
      //dispatch(loadPendingSolicitatFollowUpScionsList())
      //dispatch(loadInProgressSolicitationsList())
    },
    loadPendingSolicitations: () => {
      dispatch(loadPendingSolicitationsList());
    },
    loadInProgressSolicitations: () => {
      dispatch(loadInProgressSolicitationsList());
    },
    loadScheduledSolicitations: () => {
      dispatch(loadScheduledSolicitationsList());
    },
    onItemSelect: (event) => {
      dispatch(onSolicitationSelect(event.currentTarget.id));
    },
    onFilter: (value, type) => {
      dispatch(onSolicitationFilter(value, type));
    },
    clearDetails: () => {
      dispatch(onSolicitationUnselect());
    },
    onReopen: (event) => {
      confirmDialog(
        {
          title: 'Deseja recriar a solicitação?',
          type: 'success',
          confirmButtonColor: '#25f4af',
          confirmButtonText: 'Recriar',
        },
        () => {
          dispatch(onSolicitationReopen());
        },
      );
    },
    onCancel: (event) => {
      confirmDialog(
        {
          title: 'Deseja cancelar a solicitação?',
          type: 'warning',
          confirmButtonColor: '#25f4af',
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
        },
        () => {
          dispatch([onSolicitationCancel(), browserHistory.push('/followup')]);
        },
      );
    },
    sendPartnerWebappSMS: (telefone) => {
      dispatch(sendPartnerWebappSMS(telefone));
    },
    sendPartnerWebappChat: (event) => {
      dispatch(sendPartnerWebappChat());
    },
    sendPartnerWebappEmail: (email) => {
      dispatch(sendPartnerWebappEmail(email));
    },
    copyWebAppLinkToClipboard: (event) => {
      dispatch(copyWebAppLinkToClipboard());
    },
    onResponsavelMonitoramentoUpdate: (event) => {
      dispatch(onResponsavelMonitoramentoUpdate());
    },
    onResponsavelMonitoramentoRemove: (event) => {
      dispatch(onResponsavelMonitoramentoRemove());
    },
    onResponsavelAcionamentoUpdate: (event) => {
      dispatch(onResponsavelAcionamentoUpdate());
    },
    onResponsavelAcionamentoRemove: (event) => {
      dispatch(onResponsavelAcionamentoRemove());
    },
    onCancelCustomized: (event, type, solicitation) => {
      const isDafCompany = () =>
        solicitation.empresa.codigo === '2322661870558778503' &&
        solicitation.tipoServico.codigo === 'MECANICO';

      const generateInputOptionsForDafCompany = () => ({
        CANCELADOPORDEMORA: 'Cancelado por demora',
        CANCELADOPELOCLIENTE: 'Cancelado pelo cliente',
        CANCELADOFALTADEPECA: 'Falta de peça',
        CANCELADONAOAPROVOUORCAMENTO: 'Não aprovou orçamento',
        CANCELADOCAMINHAOFORADEGARANTIA: 'Caminhão fora de garantia',
      });

      const generateInputOptionsForNonAgendado = () => ({
        CANCELADOPORDEMORA: 'Cancelado por demora',
        CANCELADOPELOCLIENTE: 'Cancelado pelo cliente',
      });

      const generateInputOptionsForAgendado = () => ({
        CANCELADOPORMUDANCAAGENDAMENTO: 'Cancelado por mudança no agendamento',
        CANCELADOPELOCLIENTE: 'Cancelado pelo cliente',
      });

      const generateInputOptions = () => {
        if (isDafCompany()) {
          return generateInputOptionsForDafCompany();
        } else if (type !== 'agendado') {
          return generateInputOptionsForNonAgendado();
        } else {
          return generateInputOptionsForAgendado();
        }
      };

      const inputOptions = generateInputOptions();
      confirmDialogCustomized(
        {
          title: 'Deseja cancelar a solicitação?',
          icon: 'warning',
          input: 'select',
          inputWidth: '100%',
          inputOptions: inputOptions,
          inputAttributes: {
            style: `
              width: 90%;
              outline: none;
            `,
          },
          inputPlaceholder: 'Selecione um motivo',
          confirmButtonColor: '#25f4af',
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
          cancelButtonColor: '#C1C1C1',
          reverseButtons: true,
        },
        (reason) => {
          dispatch(onSolicitationCancel({codigo: reason}), browserHistory.push('/followup'));
        },
      );
    },
    onSolve: (event) => {
      confirmDialog(
        {
          title: 'Deseja resolver a solicitação?',
          type: 'success',
          confirmButtonColor: '#25f4af',
          confirmButtonText: 'Resolver',
        },
        () => {
          dispatch([onSolicitationSolve(), browserHistory.push('/followup')]);
          toastSuccess('Solicitação resolvida com sucesso.');
        },
      );
    },
    onAccept: (event) => {
      dispatch(onAcceptSolicitationResponse());
    },
    onSelectReason: (event) => {
      dispatch(onSolicitationSolutionChanged(event.target.id, event.target.value));
    },
    stopAllTimers: (event) => {
      dispatch(stopAllTimers());
    },
    onRestartSolicitation: (solicitationCode) => {
      swal(
        {
          title: 'Reiniciar a solicitação?',
          text: `<span style="color:#ed5565">
            O parceiro será removido desta solicitação.
          </span>
          <br/><br/>
          <span>
            Observações:
          </span>`,
          type: 'input',
          html: true,
          inputType: 'text',
          inputValue: '',
          showCancelButton: true,
        },
        (inputValue) => {
          if (inputValue !== false)
            return dispatch([
              {
                type: `RESTART_SOLICITATION`,
                value: solicitationCode,
              },
              putLog(inputValue, solicitationCode, HISTORICO_MODO_PRIVADO),
              browserHistory.push('/followup'),
            ]);
        },
      );
    },
    seeOnMap: (solicitation) => {
      dispatch({type: `SEE_SOLICITATION_ON_MAP`, value: solicitation});
    },
    handleClosestPartners: (solicitationCode) => {
      dispatch({type: `HANDLE_CLOSEST_PARTNERS`, value: solicitationCode});
    },
    clearClosestPartners: () => {
      dispatch({type: `CLEAR_CLOSEST_PARTNERS`});
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FollowUpView));
