import React, {Component} from 'react';

import swal from 'sweetalert2';
import {bindActionCreators} from 'redux';
import AsyncSelect from 'react-select/lib/Async';
import Select from 'react-select';
import {browserHistory} from 'react-router';
import {connect} from 'react-redux';
import moment from 'moment';
import classnames from 'classnames';
import Swal from 'sweetalert2';
import Wizard, {Step} from './WizardAdapter';
import MaskedInput from './MaskedInput';
import FailureTab, {tipoCargaOptions} from './failureTab';
import AttendenceAddressTab from './attendenceAddressTab';
import CustomModal, {doOpenModal, doCloseModal} from '../common/CustomModal';
import ValidationTab from '../attendance/tabs/ValidationTab';
import {secondsToMinutes, minutesToSeconds, toTextPlainTime} from '../../utils/time_helper';
import {selectCustomStyle} from '../../utils/SelectCustomStyle';
import {APP_PROFILES, CODIGO_DAF, USUARIO_TIPO_EMPRESA} from '../../reducers/constants';
import ServicesTab from '../../components/attendance/tabs/ServicesTab';
import {doGet, doPut} from '../../api/base/base_api_helper';
import {toastError, toastSuccess} from '../../actions/toast_actions';
import {clearServiceState} from '../../actions/service_actions';
import {getCompanies, CompaniesContracts} from '../../actions/relatorio_actions';
import {
  onChangeFailureDescription,
  newAttendence,
  clearAttendanceTab,
  onChangeActualKm,
  onChangeEstimationDistance,
  onChangeEstimationTime,
  setAttendanceChargeType,
  setAttendanceOriginAddress,
  setAttendanceEstimation,
  setAttendanceValidateInfo,
  resetAttendanceValidateInfo,
  setAttendanceDisplayRevision,
  setAttendanceTypeOfRevision,
} from '../../actions/attendence_actions';
import {clearAddress, onAddressChange, pureAddressChange} from '../../actions/address_actions';
import {doGetV2} from '../../api/base/base_api_v2_helper';
import _ from 'lodash';
import {typeSupport} from '../../api/type_helper';
import {getUsers} from '../../api/login_helper';
import {filterBases} from '../../api/base_helper';
import {hasProfiles, isTester} from '../../utils/check_permission';
import {updateOwnerUser} from '../../api/attendence_helper';

const companiesStyles = {
  menu: (provided, state) => {
    return {
      ...provided,
      zIndex: 999999,
    };
  },
  option: (provided, state) => {
    return {
      ...provided,
      cursor: 'pointer',
    };
  },
};

const tipoMotivoDAFOptions = [
  {
    value: 'TIPOASSISTENCIAMOTOR',
    label: 'Motor',
  },
  {
    value: 'TIPOASSISTENCIAPOSTRATAMENTO',
    label: 'Sistema de pós tratamento',
  },
  {
    value: 'TIPOASSISTENCIATRANSMISSAO',
    label: 'Transmissão',
  },
  {
    value: 'TIPOASSISTENCIASISTEMAELETRICO',
    label: 'Sistema elétrico',
  },
  {
    value: 'TIPOASSISTENCIACABINE',
    label: 'Cabine',
  },
  {
    value: 'TIPOASSISTENCIASISTEMADEESCAPE',
    label: 'Sistema de escape',
  },
  {
    value: 'TIPOASSISTENCIASISTEMAAR',
    label: 'Sistema de ar',
  },
  {
    value: 'TIPOASSISTENCIAEIXOTRASEIRO',
    label: 'Eixo Traseiro',
  },
  {
    value: 'TIPOASSISTENCIAEIXODIANTEIRO',
    label: 'Eixo Dianteiro',
  },
  {
    value: 'TIPOASSISTENCIASISTEMADEFREIO',
    label: 'Sistema de freio',
  },
  {
    value: 'TIPOASSISTENCIAOUTROS',
    label: 'Outros',
  },
];

const tipoMotivoOptions = [
  {
    value: 'TIPOASSISTENCIAEIXOTRACAO',
    label: 'Eixo Tração',
  },
  {
    value: 'TIPOASSISTENCIAMOTOR',
    label: 'Motor',
  },
  {
    value: 'TIPOASSISTENCIAPOSTRATAMENTO',
    label: 'Sistema de pós tratamento',
  },
  {
    value: 'TIPOASSISTENCIATRANSMISSAO',
    label: 'Transmissão',
  },
  {
    value: 'TIPOASSISTENCIASISTEMAELETRICO',
    label: 'Sistema elétrico',
  },
  {
    value: 'TIPOASSISTENCIACABINE',
    label: 'Cabine',
  },
  {
    value: 'TIPOASSISTENCIASISTEMADEESCAPE',
    label: 'Sistema de escape',
  },
  {
    value: 'TIPOASSISTENCIASISTEMAAR',
    label: 'Sistema de ar',
  },
  {
    value: 'TIPOASSISTENCIAEIXOTRASEIRO',
    label: 'Eixo Traseiro',
  },
  {
    value: 'TIPOASSISTENCIAEIXODIANTEIRO',
    label: 'Eixo Dianteiro',
  },
  {
    value: 'TIPOASSISTENCIASISTEMADEFREIO',
    label: 'Sistema de freio',
  },
  {
    value: 'TIPOASSISTENCIAOUTROS',
    label: 'Outros',
  },
];

const LegalOpsOptions = [
  {label: 'Apreensão', value: 'KOVIMOTIVOASSISTENCIA-LEGALOPS-APREENSAO'},
  {label: 'Multa Suspensiva', value: 'KOVIMOTIVOASSISTENCIA-LEGALOPS-MULTASUSPENSIVA'},
  {label: 'PT (Issue)', value: 'KOVIMOTIVOASSISTENCIA-LEGALOPS-PT'},
  {
    label: 'Sinistro Colisão (Carro não anda)',
    value: 'KOVIMOTIVOASSISTENCIA-LEGALOPS-SINISTROCOLISAO',
  },
  {
    label: 'Sinistro - Acordado com Driver',
    value: 'KOVIMOTIVOASSISTENCIA-LEGALOPS-SINISTROCOACORDADO',
  },
  {
    label: 'Sinistro Itens de segurança danificados',
    value: 'KOVIMOTIVOASSISTENCIA-LEGALOPS-SINISTROITENSSEGURANCADANIFICADOS',
  },
  {
    label: 'Perda Placa',
    value: 'KOVIMOTIVOASSISTENCIA-LEGALOPS-PERDAPLACA',
  },
];

const prevençãoDePerdasOptions = [
  {label: 'Abandono', value: 'KOVIMOTIVOASSISTENCIA-PREVENCAOPERDAS-ABANDONO'},
  {label: 'Apropriação', value: 'KOVIMOTIVOASSISTENCIA-PREVENCAOPERDAS-APROPRIACAO'},
  {
    label: 'Veículo andando bloqueado (Zombie)',
    value: 'KOVIMOTIVOASSISTENCIA-PREVENCAOPERDAS-VEICULOANDANDOBLOQUEADO',
  },
  {
    label: 'Recolha inadimplente - acordado com drive',
    value: 'KOVIMOTIVOASSISTENCIA-PREVENCAOPERDAS-INADIMPLENTE',
  },
  {
    label: 'Roubo-Furto - Bem-sucedido, mas localizado',
    value: 'KOVIMOTIVOASSISTENCIA-PREVENCAOPERDAS-ROUBOFURTOBEMSUCEDIDO',
  },
  {
    label: 'Tentativa Roubo e Furto - Malsucedido',
    value: 'KOVIMOTIVOASSISTENCIA-PREVENCAOPERDAS-TENTATIVAROUBOFURTOBEMSUCEDIDO',
  },
];

const perdaDeItensOptions = [
  {label: 'Sos Pane seca', value: 'KOVIMOTIVOASSISTENCIA-PERDASITENS-PANESECA'},
  {label: 'Perda Chave', value: 'KOVIMOTIVOASSISTENCIA-PERDASITENS-CHAVE'},
  {label: 'Perda Token', value: 'KOVIMOTIVOASSISTENCIA-SOSTOKEN'},
  {label: 'Sos Chaveiro', value: 'KOVIMOTIVOASSISTENCIA-SOSCHAVEIRO'},
  {label: 'Sos Pneu', value: 'KOVIMOTIVOASSISTENCIA-SOSPNEU'},
];

const veiculoNaoLigaOptions = [
  {
    label: 'Movimentação: acatar bloqueio ou desbloqueio',
    value: 'KOVIMOTIVOASSISTENCIA-VEICULONAOLIGA-BLOQUEIODESBLOQUEIO',
  },
  {
    label: 'Problemas KTS ou Token',
    value: 'KOVIMOTIVOASSISTENCIA-VEICULONAOLIGA-PROBLEMAKTSOUTOKEN',
  },
  {
    label: 'Movimentação - sem sinal KTS',
    value: 'KOVIMOTIVOASSISTENCIA-VEICULONAOLIGA-SEMSINALKTS',
  },
  {
    label: 'Guincho pós-Sos de bateria',
    value: 'KOVIMOTIVOASSISTENCIA-VEICULONAOLIGA-GUINCHOPOSSOSBATERIA',
  },

  {
    label: 'Sos bateria abaixo de 11v',
    value: 'KOVIMOTIVOASSISTENCIA-VEICULONAOLIGA-BATERIAABAIXO11V',
  },
  {
    label: 'Correia do alternador',
    value: 'KOVIMOTIVOASSISTENCIA-VEICULONAOLIGA-CORREIAALTERNADOR',
  },
  {
    label: 'Não liga - Outros problemas mecânicos',
    value: 'KOVIMOTIVOASSISTENCIA-VEICULONAOLIGA-OUTROPROBLEMA',
  },
];

const problemasMecanicosLigandoOptions = [
  {
    label: 'Vazamento',
    value: 'KOVIMOTIVOASSISTENCIA-PROBLEMAMECANICOLIGANDO-VAZAMENTO',
  },
  {
    label: 'Superaquecimento',
    value: 'KOVIMOTIVOASSISTENCIA-PROBLEMAMECANICOLIGANDO-FUMACA',
  },
  {
    label: 'Luzes no painel',
    value: 'KOVIMOTIVOASSISTENCIA-PROBLEMAMECANICOLIGANDO-LUZPAINEL',
  },
  {
    label: 'Embreagem',
    value: 'KOVIMOTIVOASSISTENCIA-PROBLEMAMECANICOLIGANDO-EMBREAGEM',
  },
  {
    label: 'Sem força ou dando tranco',
    value: 'KOVIMOTIVOASSISTENCIA-PROBLEMAMECANICOLIGANDO-SEMFORCAOUDANDOTRANCO',
  },
  {
    label: 'Cambio travado',
    value: 'KOVIMOTIVOASSISTENCIA-PROBLEMAMECANICOLIGANDO-CAMBIOTRAVADO',
  },
  {
    label: 'Volante travado',
    value: 'KOVIMOTIVOASSISTENCIA-PROBLEMAMECANICOLIGANDO-VOLANTETRAVADO',
  },
  {
    label: 'Roda travada',
    value: 'KOVIMOTIVOASSISTENCIA-PROBLEMAMECANICOLIGANDO-RODATRAVADA',
  },
  {
    label: 'Freio de mão',
    value: 'KOVIMOTIVOASSISTENCIA-PROBLEMAMECANICOLIGANDO-FREIODEMAO',
  },
  {
    label: 'Veiculo não freia',
    value: 'KOVIMOTIVOASSISTENCIA-PROBLEMAMECANICOLIGANDO-VEICULONAOFREIA',
  },
  {
    label: 'Suspensão ou Amortecedor',
    value: 'KOVIMOTIVOASSISTENCIA-PROBLEMAMECANICOLIGANDO-SUSPENSAOOUAMORTECEDOR',
  },
  {
    label: 'Cheiros incomuns',
    value: 'KOVIMOTIVOASSISTENCIA-PROBLEMAMECANICOLIGANDO-CHEIROINCOMUM',
  },
];

const movimentacaoInternaOptions = [
  {
    label: 'Movimentação Oficina',
    value: 'KOVIMOTIVOASSISTENCIA-MOVIMENTACAOINTERNA-OFICINA',
  },
  {
    label: 'Movimentação Sinistro',
    value: 'KOVIMOTIVOASSISTENCIA-MOVIMENTACAOINTERNA-SINISTRO',
  },
  {
    label: 'Movimentação Pátio',
    value: 'KOVIMOTIVOASSISTENCIA-MOVIMENTACAOINTERNA-PATIO',
  },
];

const movimentacaoExternaOptions = [
  {
    label: 'Movimentação Oficina',
    value: 'KOVIMOTIVOASSISTENCIA-MOVIMENTACAOEXTERNA-OFICINA',
  },
];

const devolucaoOptions = [{label: 'Devolução', value: 'KOVIMOTIVOASSISTENCIA-DEVOLUCAO'}];

const tipoMotivoKoviOptions = (state) => {
  const options = [
    {
      label: 'Legal Ops',
      options: LegalOpsOptions,
    },
    {
      label: 'Prevenção de perdas (safety)',
      options: prevençãoDePerdasOptions,
    },
    {
      label: 'Sos & Periféricos',
      options: perdaDeItensOptions,
    },
    {
      label: 'Veículo não liga ',
      options: veiculoNaoLigaOptions,
    },
    {
      label: 'Veículo ligando - Problemas mecânico',
      options: problemasMecanicosLigandoOptions,
    },
    {
      label: 'Movimentação Interna',
      options: movimentacaoInternaOptions,
    },
    {
      label: 'Devolução',
      options: devolucaoOptions,
    },
  ];

  // mudar para o departamento de prod
  if (state.departaments.some((element) => element.code === '3504752550966465538')) {
    options.push({
      label: 'Movimentação Externa',
      options: movimentacaoExternaOptions,
    });
  }

  return options;
};

const koviOptionsFlat = [
  ...LegalOpsOptions,
  ...prevençãoDePerdasOptions,
  ...perdaDeItensOptions,
  ...veiculoNaoLigaOptions,
  ...problemasMecanicosLigandoOptions,
  ...movimentacaoInternaOptions,
  ...devolucaoOptions,
  ...movimentacaoExternaOptions,
];

const sacOptions = [
  {
    value: 'SACINFORMACAO',
    label: 'Informação',
  },
  {
    value: 'SACRECLAMACAO',
    label: 'Reclamação',
  },
  {
    value: 'DAF_SAC_SUGESTAO',
    label: 'Sugestão',
  },
  {
    value: 'DAF_SAC_ELOGIO',
    label: 'Elogio',
  },
];

const recusaOptions = [
  // {
  //   value: 'DAF_RECUSA_FALTA_MAO_DE_OBRA',
  //   label: 'Falta de mão-de-obra disponível para atendimento',
  // },
  {
    value: 'DAF_RECUSA_NAO_CUMPRIMENTO_DO_HORARIO_AGENDADO',
    label: 'Não cumprimento do horário agendado com o cliente',
  },
  {
    value: 'RECUSA_PARA_ATENDER',
    label: 'Recusa de atendimento',
  },
  {
    value: 'DAF_RECUSA_FALTA_PECAS_CONCESSIONARIA',
    label: 'Falta de peças na concessionária',
  },
  {
    value: 'DAF_RECUSA_DEMORA_ATENDIMENTO',
    label: 'Demora para realizar atendimento',
  },
  {
    value: 'DAF_RECUSA_ACEITE_MANUAL_APP',
    label: 'Aceite manual do App',
  },
  {
    value: 'RECUSA_TELEFONE_NAO_ATENDE',
    label: 'Telefone de plantão não atende',
  },
  // {
  //   value: 'RECUSA_DESCONHECIMENTO_PROCEDIMENTO',
  //   label: 'Desconhecimento de procedimento/política',
  // },
  // {
  //   value: 'RECUSA_DADOS_INCORRETOS',
  //   label: 'Fornecimento de dados incorretos para a Central',
  // },
  {
    value: 'RECUSA_FALTA_CONTATO_CLIENTE',
    label: 'Reparo não evidenciado',
  },
  {
    value: 'RECUSA_NAO_USOU_APP',
    label: 'Não usou o aplicativo',
  },
  {
    value: 'RECUSA_OUTROS',
    label: 'Outros',
  },
];

const complaintOptions = [
  {
    value: 'DIAGNOSTICOTARDIO',
    label: 'Diagnóstico tardio',
  },
  {
    value: 'QUALIDADEDOATENDIMENTO',
    label: 'Qualidade do atendimento',
  },
  {
    value: 'GARANTIA',
    label: 'Garantia',
  },
  {
    value: 'QUALIDADEDOSERVIÇO',
    label: 'Qualidade do serviço',
  },
  {
    value: 'PECASDISPONIVEIS',
    label: 'Peças indisponíveis',
  },
  {
    value: 'QUALIDADEDOPRODUTO',
    label: 'Qualidade do produto',
  },
  {
    value: 'ASSISTENCIADAF',
    label: 'Assistência DAF',
  },
];

class RegisterAttendence extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 1,
      company: '',
      contact: '',
      phone: '',
      falha: '',
      requisicaoTipo: '',
      tipoMotivo: {
        label: 'Selecione o tipo de assistência',
        value: '',
      },
      tipoReclamacao: {
        label: 'Selecione o tipo de reclamacao',
        value: '',
      },
      tipoMotivoInconformidade: {
        label: 'Selecione o tipo de recusa',
        value: '',
      },
      areaSuporte: '',
      suporte: {},
      origem: '',
      veiculoCliente: '',
      optionsVeiculoClient: [],
      nome: '',
      document: '',
      showInfo: false,
      carro: {
        modelo: '',
        placa: '',
        chassis: '',
      },
      contract: {
        produto: '',
        vigenciaInicio: '',
        vigenciaFinal: '',
        frequencias: [],
      },
      premium: false,
      concessionaria: '',
      chassi: '',
      dataFinalGarantia: '',
      codigoAtendimentoInconformidade: '',
      codigoInconformidade: '',
      failureInconformidade: '',
      clienteSolicitations: [],
      isFirstLoad: true,
      departaments: [],
      bases: [],
      selectedBaseCode: null,
      selectedBase: null,
      usuarioResponsavel: null,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.clearFields = this.clearFields.bind(this);
    this.handleCreateAttendence = this.handleCreateAttendence.bind(this);
    this.checkFieldsRequired = this.checkFieldsRequired.bind(this);
    this.doAfterCreateAttendence = this.doAfterCreateAttendence.bind(this);
    this.doAfterCreateInconformidade = this.doAfterCreateInconformidade.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.setFieldsWithProps = this.setFieldsWithProps.bind(this);
    this.confirmCreateAttendence = this.confirmCreateAttendence.bind(this);
    this.setFieldsValidationTab = this.setFieldsValidationTab.bind(this);
    this.setFavoriteAddress = this.setFavoriteAddress.bind(this);
    this.setDestinyAddress = this.setDestinyAddress.bind(this);
    this.buildCompaniesOptions = this.buildCompaniesOptions.bind(this);
    this.handleTypeRequest = this.handleTypeRequest.bind(this);
    this.handleShowTipoRevisao = this.handleShowTipoRevisao.bind(this);
    this.onChangeVeiculoCliente = this.onChangeVeiculoCliente.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.pegarResponsabilidade = this.pegarResponsabilidade.bind(this);
    this.verifyLogin = this.verifyLogin.bind(this);
    this.getCienteSolicitation = this.getCienteSolicitation.bind(this);
    this.changeCliente = this.changeCliente.bind(this);
    this.getComplaintOptions = this.getComplaintOptions.bind(this);
  }

  componentWillMount() {
    const {FREQUENCIA, GESTAO} = CompaniesContracts;
    this.props.getCompanies(`${FREQUENCIA},${GESTAO}`);
  }

  handleSubmit(event) {
    event.preventDefault();
    if (!this.state.requisicaoTipo) {
      return toastError('Preencha o tipo de requisição');
    }
    if (
      this.state.requisicaoTipo === 'REQUISICAOTIPOASSISTENCIA' &&
      !this.state.tipoMotivo.value
    ) {
      return toastError('Preencha o tipo de assistência');
    }
    if (
      this.state.requisicaoTipo === 'REQUISICAOTIPOINFORMACAO' &&
      !this.state.tipoMotivo.value
    ) {
      return toastError('Preencha o tipo de SAC');
    }
    if (this.state.requisicaoTipo === 'REQUISICAOTIPOSUPORTE') {
      if (!this.state.tipoReclamacao.value) return toastError('Preencha o tipo de Suporte');
      if (
        !this.props.attendanceId &&
        !this.state.selectedBaseCode &&
        this.state.tipoReclamacao.value !== 'TIPOSUPORTENOVOPRESTADOR'
      )
        return toastError('Preencha a Base');
    }
    if (
      this.state.tipoMotivo &&
      this.state.tipoMotivo.value === 'SACRECLAMACAO' &&
      !this.state.tipoReclamacao.value
    ) {
      return toastError('Preencha o tipo de reclamação');
    }
    if (this.props.type === 'create') {
      this.confirmCreateAttendence();
    }
  }

  confirmCreateAttendence() {
    swal
      .fire({
        title: 'Deseja criar um novo atendimento?',
        showCancelButton: true,
        icon: 'warning',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Gerar',
        reverseButtons: true,
      })
      .then(
        (value) => {
          if (value.dismiss === 'cancel') {
            return;
          }
          this.handleCreateAttendence();
        },
        () => {},
      );
  }

  setFieldsValidationTab(attendanceTab = {}) {
    if (!attendanceTab.veiculo) return;
    const {
      veiculo: {
        enderecoFavorito: {
          descricao = '',
          email = '',
          endereco: {logradouro = ''} = {},
          contato = '',
          telefoneContato = '',
          contato2 = '',
          telefoneContato2 = '',
          contato3 = '',
          telefoneContato3 = '',
        } = {},
        cliente: {premium = false} = {},
        chassis = '',
        dataFinalGarantia = '',
        dataFinalGarantiaEstendida1 = '',
        descricaoGarantiaEstendida1 = '',
        dataFinalGarantiaEstendida2 = '',
        descricaoGarantiaEstendida2 = '',
      },
    } = attendanceTab;

    this.props.setAttendanceValidateInfo({
      chassi: chassis,
      concessionaria: descricao && logradouro ? `${descricao} | ${logradouro}` : '',
      premium,
      dataFinalGarantia,
      dataFinalGarantiaEstendida1,
      descricaoGarantiaEstendida1,
      dataFinalGarantiaEstendida2,
      descricaoGarantiaEstendida2,
      email,
      contato,
      telefoneContato,
      contato2,
      telefoneContato2,
      contato3,
      telefoneContato3,
    });
  }

  getRecusaOptions() {
    if (this.state.company && this.state.company.value === '2322661870558778503') {
      return recusaOptions;
    }
    return recusaOptions.filter(
      (option) =>
        option.value !== 'DAF_RECUSA_FALTA_PECAS_CONCESSIONARIA' &&
        option.value !== 'DAF_RECUSA_DEMORA_ATENDIMENTO' &&
        option.value !== 'DAF_RECUSA_ACEITE_MANUAL_APP',
    );
  }

  setFieldsWithProps(selectedProps) {
    const {attendanceTab} = selectedProps;

    const companyCode = attendanceTab.cliente && attendanceTab.cliente.codigoEmpresa;
    const isKovi = companyCode === '2200248530130441273';

    this.handleShowTipoRevisao(attendanceTab.tipoRequisicao.codigo);
    if (attendanceTab.tipoRevisao) {
      this.props.setAttendanceTypeOfRevision(attendanceTab.tipoRevisao);
    }
    let tipoMotivo = {};

    if (
      attendanceTab.tipoMotivo &&
      attendanceTab.tipoMotivo.codigo &&
      attendanceTab.tipoRequisicao.codigo === 'REQUISICAOTIPOINFORMACAO'
    ) {
      tipoMotivo = sacOptions.find((item) => item.value === attendanceTab.tipoMotivo.codigo);
    } else if (attendanceTab.tipoRequisicao.codigo === 'REQUISICAOTIPOASSISTENCIA') {
      tipoMotivo = isKovi
        ? koviOptionsFlat.find((item) => {
            const tipoMotivoCodigo =
              attendanceTab.tipoMotivo && attendanceTab.tipoMotivo.codigo;

            return item.value === tipoMotivoCodigo;
          })
        : tipoMotivoOptions.find((item) => {
            const tipoMotivoCodigo =
              attendanceTab.tipoMotivo && attendanceTab.tipoMotivo.codigo;

            return item.value === tipoMotivoCodigo;
          });
    } else if (attendanceTab.tipoRequisicao.codigo === 'REQUISICAOTIPORECUSA') {
      tipoMotivo = recusaOptions.find((item) => {
        const tipoMotivoCodigo = attendanceTab.tipoMotivo && attendanceTab.tipoMotivo.codigo;

        return item.value === tipoMotivoCodigo;
      });
    }

    const tipoReclamacao = {};

    if (attendanceTab.tipoReclamacao) {
      tipoReclamacao.label = attendanceTab.tipoReclamacao.descricao;
      tipoReclamacao.value = attendanceTab.tipoReclamacao.value;
    }

    const newState = {
      ...this.state,
      tipoMotivo,
      tipoReclamacao,
      requisicaoTipo: attendanceTab.tipoRequisicao.codigo,
      origemRequisicao: attendanceTab.origemRequisicao.codigo,
      phone: attendanceTab.telefone,
      contact: attendanceTab.contato,
      origem: attendanceTab.origemRequisicao.codigo,
      veiculoCliente: {
        label: attendanceTab.veiculo
          ? `${attendanceTab.veiculo.cliente.nome} | ${attendanceTab.veiculo.placa}`
          : '',
        value: attendanceTab.veiculo,
      },
      document: attendanceTab.veiculo ? attendanceTab.veiculo.cliente.cpf : '',
      nome: attendanceTab.veiculo ? attendanceTab.veiculo.cliente.nome : '',
      showInfo: !!attendanceTab.veiculo,
      index: attendanceTab.veiculo ? 0 : 1,
      codigoInconformidade: attendanceTab.codigoAtendimentoInconformidade,
      codigo: attendanceTab.codigo,
    };

    if (attendanceTab.usuarioResponsavel) {
      newState.usuarioResponsavel = attendanceTab.usuarioResponsavel;
    }

    if (attendanceTab.base) {
      newState.selectedBase = attendanceTab.base;
    }

    newState.carro.modelo = attendanceTab.veiculo
      ? `${attendanceTab.veiculo.marca} ${attendanceTab.veiculo.modelo}`
      : '';
    newState.carro.placa = attendanceTab.veiculo ? attendanceTab.veiculo.placa : '';

    newState.carro.chassis = attendanceTab.veiculo ? attendanceTab.veiculo.chassis : '';

    if (this.companiesOptions) {
      const company = this.companiesOptions.find((company) => {
        return company.value === attendanceTab.empresa.codigo;
      });

      newState.company = company;
    }

    const {veiculo} = attendanceTab;
    if (attendanceTab.veiculo) {
      const latestContract = this.getLastContract(veiculo.contratos, veiculo);
      if (latestContract) {
        newState.contract.produto = latestContract.produto.descricao;
        newState.contract.vigenciaInicio = `De ${latestContract.dataInicioVigencia}`;
        newState.contract.vigenciaFinal = `à ${latestContract.dataFinalVigencia}`;
        newState.contract.frequencias = latestContract.produto.frequencias;
      }

      this.setFieldsValidationTab(attendanceTab);
    }

    this.props.onChangeFailureDescription(attendanceTab.requisicao);
    if (attendanceTab.kmAtual) this.props.onChangeActualKm(attendanceTab.kmAtual);

    if (attendanceTab.tipoCarga) this.props.setAttendanceChargeType(attendanceTab.tipoCarga);

    if (attendanceTab.distanciaAteoCliente) {
      this.props.setAttendanceEstimation('distanceUnit', attendanceTab.distanciaAteoCliente);
      this.props.setAttendanceEstimation('distance', attendanceTab.distanciaAteoCliente);
    }

    if (attendanceTab.tempoAteoCliente) {
      // seconds
      const {tempoAteoCliente} = attendanceTab;
      const durationMinutes = minutesToSeconds(tempoAteoCliente);
      this.props.setAttendanceEstimation('time', toTextPlainTime(durationMinutes));
      this.props.setAttendanceEstimation('timeUnit', durationMinutes);
    }

    if (
      attendanceTab.tipoMotivo &&
      attendanceTab.tipoMotivo.codigo &&
      attendanceTab.tipoRequisicao.codigo === 'REQUISICAOTIPOSUPORTE'
    ) {
      newState.tipoMotivo = {
        label: attendanceTab.tipoMotivo.descricao,
        value: attendanceTab.tipoMotivo.codigo,
      };
      newState.tipoReclamacao = {
        label: attendanceTab.tipoReclamacao.descricao,
        value: attendanceTab.tipoReclamacao.codigo,
      };
    }

    this.setDestinyAddress(attendanceTab);

    this.setFavoriteAddress(attendanceTab);

    this.setState(newState);
  }

  async pegarResponsabilidade() {
    const response = await updateOwnerUser(this.state.codigo);
    const {login} = this.props;

    if (response) {
      this.setState({
        ...this.state,
        usuarioResponsavel: login.user_info.usuario,
      });
    }
  }

  handleClick() {
    const {codigoInconformidade} = this.state;
    if (codigoInconformidade) {
      const novaAba = window.open(`/assistance/${codigoInconformidade}/details`, '_blank');
      novaAba.focus();
    }
  }

  setDestinyAddress(attendanceTab) {
    if (!attendanceTab.endereco) return;

    const enderecoDestino = [
      attendanceTab.endereco.logradouro,
      attendanceTab.endereco.latitude,
      attendanceTab.endereco.longitude,
      attendanceTab.endereco.numero,
      attendanceTab.endereco.bairro,
      attendanceTab.endereco.cidade,
    ];

    this.props.pureAddressChange('enderecoDestino', ...enderecoDestino);
  }

  setFavoriteAddress(attendanceTab) {
    const favorito = attendanceTab.enderecoFavorito;

    this.props.setAttendanceOriginAddress({
      className: 'favorite-address',
      value: attendanceTab.enderecoFavorito,
      label: favorito ? '✭  ' + favorito.descricao : '',
    });

    if (favorito) {
      const enderecoOrigemProps = [
        attendanceTab.enderecoFavorito.endereco.logradouro,
        attendanceTab.enderecoFavorito.endereco.latitude,
        attendanceTab.enderecoFavorito.endereco.longitude,
        attendanceTab.enderecoFavorito.endereco.numero,
        attendanceTab.enderecoFavorito.endereco.bairro,
        attendanceTab.enderecoFavorito.endereco.cidade,
      ];

      this.props.pureAddressChange('enderecoOrigem', ...enderecoOrigemProps);
      this.props.pureAddressChange('attendanceEnderecoDestino', ...enderecoOrigemProps);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.attendanceTab != nextProps.attendanceTab) {
      this.setFieldsWithProps(nextProps);
    }
  }

  checkFieldsRequired() {
    const {endereco} = this.props;
    if (this.state.company === '') throw new Error('Preencha o campo Empresa.');
    if (this.state.veiculoCliente === '') throw new Error('Preencha o campo Veículo/cliente.');
    if (this.state.requisicaoTipo === '') throw new Error('Preencha o Tipo de Requisição.');
    if (this.state.requisicaoTipo === 'REQUISICAOTIPOASSISTENCIA' && !this.state.tipoMotivo)
      throw new Error('Preencha o tipo do Problema.');

    if (this.state.requisicaoTipo === 'REQUISICAOTIPOINFORMACAO' && !this.state.tipoMotivo)
      throw new Error('Preencha o tipo do SAC.');

    if (this.state.origem === '') throw new Error('Preencha o campo origem.');
    if (!endereco || !endereco.hasOwnProperty('logradouro'))
      throw new Error('Preencha a aba de endereço');
  }

  doAfterCreateAttendence({atendimento}) {
    try {
      this.clearFields();
    } catch (err) {
      console.log(err);
    }
    browserHistory.push(`/assistance/${atendimento.codigo}/details`);
  }

  doAfterSetNewCLiente(codigoAtendimento) {
    const {veiculoCliente} = this.state;

    this.setState({
      veiculoCliente: {
        label: veiculoCliente.label,
        value: veiculoCliente.value,
      },
    });
    this.props.updateAtendimentoTab();
  }

  doAfterCreateInconformidade({atendimento}) {
    window.open(`/assistance/${atendimento.codigo}/details`, '_blank');
  }

  viewAttendance(atendimento) {
    window.open(`/assistance/${atendimento}/details`, '_blank');
  }

  handleCreateAttendence() {
    const {
      newAttendence,
      enderecoDestino,
      failure,
      time,
      actualKm,
      distanceUnit,
      tipoCarga,
      originAddress,
      tipoRevisao,
    } = this.props;
    const {
      company: {value: companyCode = ''} = {},
      contact: contato,
      phone: telefone,
      codigoAtendimentoInconformidade: codigoInconformidade,
      origem,
      tipoMotivo,
      tipoReclamacao,
      requisicaoTipo: tipoRequisicao,
      veiculoCliente: {value: {codigo: codigoVeiculo} = {}},
      selectedBaseCode,
    } = this.state;

    const company = {
      companyCode,
      contact: {
        contato: contato ? contato : '',
        telefone: telefone ? telefone : '',
      },
    };

    const requestInfo = {
      requisicao: failure ? failure : '',
      origemRequisicao: origem ? origem : '',
      tipoRequisicao: tipoRequisicao ? tipoRequisicao : '',
      tipoMotivo: tipoMotivo.value,
      tipoReclamacao: tipoReclamacao.value,
    };

    const others = {
      base: selectedBaseCode,
      kmAtual: actualKm,
      codigoAtendimentoInconformidade: codigoInconformidade,
      enderecoFavorito:
        originAddress && originAddress.value ? originAddress.value.codigo : null,
      distanciaAteoCliente: distanceUnit,
      tempoAteoCliente: time ? secondsToMinutes(time) : null,
      tipoRevisao,
    };
    if (tipoCargaOptions.some((option) => option.value === tipoCarga)) {
      others.codigoTipoCarga = tipoCarga;
    } else {
      others.tipoCarga = tipoCarga;
    }

    newAttendence(
      company,
      requestInfo,
      codigoVeiculo,
      enderecoDestino,
      others,
      this.doAfterCreateAttendence,
    );
  }

  criarInconformidade(type, description) {
    const tipoMotivoInconformidade = type;
    const {
      newAttendence,
      enderecoDestino,
      failure,
      time,
      actualKm,
      distanceUnit,
      tipoCarga,
      originAddress,
      tipoRevisao,
      attendanceId,
      attendanceTab,
    } = this.props;
    const {
      company: {value: companyCode = ''} = {},
      contact: contato,
      phone: telefone,
      origem,
      tipoMotivo,
      requisicaoTipo: tipoRequisicao,
      veiculoCliente: {value: {codigo: codigoVeiculo} = {}},
    } = this.state;

    const company = {
      companyCode,
      contact: {
        contato: contato ? contato : '',
        telefone: telefone ? telefone : '',
      },
    };

    const requestInfo = {
      requisicao: description ? description : '',
      origemRequisicao: origem ? origem : '',
      tipoRequisicao: 'REQUISICAOTIPORECUSA',
      tipoMotivo: tipoMotivoInconformidade.value,
    };
    const tipoCargaModificado =
      attendanceTab && attendanceTab.tipoCargaDaf && attendanceTab.tipoCargaDaf.codigo
        ? {codigoTipoCarga: attendanceTab.tipoCargaDaf.codigo}
        : {tipoCarga};
    const others = {
      kmAtual: actualKm,
      ...tipoCargaModificado,
      codigoAtendimentoInconformidade: attendanceId,
      enderecoFavorito:
        originAddress && originAddress.value ? originAddress.value.codigo : null,
      distanciaAteoCliente: distanceUnit,
      tempoAteoCliente: time ? secondsToMinutes(time) : null,
      tipoRevisao,
    };
    newAttendence(
      company,
      requestInfo,
      codigoVeiculo,
      enderecoDestino,
      others,
      this.doAfterCreateInconformidade,
      doCloseModal('CONTEXT_CRIAR_INCONFORMIDADE'),
      this.setState({
        tipoMotivoInconformidade: {
          label: 'Selecione o tipo de recusa',
          value: '',
        },
        failureInconformidade: '',
      }),
    );
  }

  resolveClientOptions(result) {
    if (result.sucesso) {
      const customers = result.listVehicles.map((customer) => ({
        value: customer,
        label: `${customer.cliente.nome} | ${
          customer.placa ? customer.placa : customer.chassis
        }`,
      }));
      return customers;
    }
  }

  onChangeFailure = (value) => {
    this.setState({
      failureInconformidade: value,
    });
  };

  clearFields() {
    this.setState({
      index: 1,
      company: '',
      contact: '',
      phone: '',
      falha: '',
      requisicaoTipo: '',
      tipoMotivo: '',
      tipoReclamacao: '',
      origem: '',
      veiculoCliente: '',
      nome: '',
      document: '',
      showInfo: false,
      carro: {
        modelo: '',
        placa: '',
        chassis: '',
      },
      contract: {
        produto: '',
        vigenciaInicio: '',
        vigenciaFinal: '',
        frequencias: [],
      },
    });

    this.props.onChangeFailureDescription('');
    this.props.onChangeEstimationDistance('');
    this.props.onChangeEstimationTime('');
    this.props.onChangeActualKm('');
    this.props.clearAddress('enderecoOrigem');
    this.props.clearAddress('enderecoDestino');
    this.props.setAttendanceChargeType('');
    this.props.setAttendanceOriginAddress('');
    this.props.resetAttendanceValidateInfo();
    this.props.setAttendanceTypeOfRevision('');
    this.props.setAttendanceDisplayRevision(false);
  }

  handleClear() {
    if (this.props.type === 'atualizar') {
      this.setFieldsWithProps(this.props);
    } else {
      this.clearFields();
    }
  }

  componentWillUnmount() {
    this.clearFields();
    this.props.clearAttendanceTab();
  }

  buildCompaniesOptions() {
    return this.props.companies.map((c) => ({
      value: c.codigo,
      label: c.nomeFantasia,
      color: c.statusEmpresa.descricao === 'Ativo' ? '#5a5a5a' : '#cecece',
    }));
  }

  getSacOptions() {
    if (this.state.company && this.state.company.value === '2322661870558778503') {
      return sacOptions;
    }
    return sacOptions.filter(
      (option) => option.value !== 'DAF_SAC_SUGESTAO' && option.value !== 'DAF_SAC_ELOGIO',
    );
  }

  getSupportOptions() {
    const suporte = this.state.suporte;
    const response = Object.values(suporte).map((sup) => ({
      label: sup.description,
      value: sup.code,
    }));
    return response;
  }

  getSupportTypeOptions() {
    const area = this.state.areaSuporte;
    if (area === '') return [];

    const tipos = this.state.suporte[area.slice(4)].types;
    return tipos;
  }

  getLastContract(contracts, value) {
    if (!Array.isArray(contracts)) {
      if (value.contrato) return value.contrato;

      return null;
    }

    if (value.contrato) return value.contrato;

    const contractItems = contracts.map((contract) => {
      return {
        ...contract,
        dataFinal: moment(contract.dataFinalVigencia, 'DD/MM/YYYY hh:mm:ss'),
        dataInicio: moment(contract.dataInicioVigencia, 'DD/MM/YYYY hh:mm:ss'),
      };
    });

    contractItems.sort((a, b) => {
      if (a.dataFinal.isAfter(b.dataFinal)) {
        return 1;
      } else if (a.dataFinal.isBefore(b.dataFinal)) {
        return -1;
      }

      return 0;
    });

    contractItems.reverse();
    return contractItems[0];
  }

  showClientSolicitationModal(solicitations) {
    const content = document.createElement('div');

    const subTitle = document.createElement('p');
    subTitle.textContent = 'Os seguintes atendimentos abaixo foram criados recentemente:';
    content.appendChild(subTitle);

    solicitations.forEach((soli) => {
      const soliElement = document.createElement('div');
      soliElement.style.display = 'flex';
      soliElement.style.flexDirection = 'column';
      soliElement.style.alignItems = 'center';

      const soliLink = document.createElement('a');
      soliLink.href = '#';
      soliLink.textContent = soli.code;
      soliLink.style.textDecoration = 'none';
      soliLink.style.outline = 'none';
      soliLink.addEventListener('click', () => this.viewAttendance(soli.code));

      soliElement.appendChild(soliLink);
      content.appendChild(soliElement);
    });

    Swal.fire({
      title: 'Atendimento Criado Recentemente',
      html: content,
      showCloseButton: false,
      focusConfirm: false,
      confirmButtonText: 'Entendi',
      allowOutsideClick: false,
      icon: 'warning',
      onBeforeOpen: () => {
        const confirmButton = Swal.getConfirmButton();
        confirmButton.style.backgroundColor = '#5c40ff';
      },
    });
  }

  changeCliente() {
    const {veiculoCliente} = this.state;
    const {attendanceTab} = this.props;
    const codigoAtendimento = attendanceTab.codigo;
    const codigoSubscriber = veiculoCliente.value.codigo;
    if (!codigoSubscriber) {
      toastError('Selecione um cliente!');
      return;
    }
    Swal.fire({
      title: 'Confirmação',
      text: 'Deseja realmente alterar o cliente?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Cancelar',
      customClass: {
        confirmButton: 'swal-confirm-button-class',
      },
      onOpen: () => {
        document.querySelector('.swal-confirm-button-class').style.backgroundColor = '#f8ac59';
      },
    }).then((result) => {
      if (result.value === true) {
        const url = `atendimentos/subscriber`;
        const payload = {
          codigoAtendimento,
          codigoSubscriber,
        };

        return doPut(url, payload)
          .then(() => {
            toastSuccess('Cliente Alterado com sucesso!');
            this.doAfterSetNewCLiente(codigoAtendimento);
          })
          .catch((error) => {
            toastError(error.mensagemCliente);
          });
      }
    });
  }

  getCienteSolicitation() {
    const {veiculoCliente, company} = this.state;
    if (veiculoCliente) {
      const clientCode = veiculoCliente.value.codigoCliente;
      const companyCode = veiculoCliente.value.cliente.codigoEmpresa;
      const veiculoPlaca = veiculoCliente.value.placa;
      let url = `attendances/clients/attendancies?clientCode=${clientCode}&companyCode=${companyCode}`;

      return doGetV2(url)
        .then((response) => {
          this.setState({clienteSolicitations: response});

          if (
            Array.isArray(response) &&
            response.some((item) => item.vehicle.plate === veiculoPlaca)
          ) {
            this.showClientSolicitationModal(response);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }

  getClientNameOptionsDebounced = _.debounce((input, resolve, reject) => {
    if (!input) Promise.resolve(this.state.optionsVeiculoClient);

    let url = `subscriptions?_filter=${input}`;

    /* const companies = this.state.company.map(item => item.value.trim()).join(',');
        url += `&_empresa=${companies}`
        */
    this.resolveClientOptions;
    if (this.state.company) {
      url += `&_empresa=${this.state.company.value.trim()}`;
    }

    return doGet(url)
      .then((result) => {
        resolve(this.resolveClientOptions(result));
      })
      .catch((err) => {
        console.error('erro:', err);
        reject(err);
      });
  }, 300);

  getClientNameOptions = (input) => {
    return new Promise((resolve, reject) => {
      this.getClientNameOptionsDebounced(input, resolve, reject);
    });
  };

  onChangeVeiculoCliente(veiculoCliente) {
    const newState = {
      ...this.state,
      veiculoCliente,
    };

    if (veiculoCliente && veiculoCliente.value) {
      const {value} = veiculoCliente;
      newState.nome = value.cliente.nome;
      newState.document = value.cliente.cpf;
      newState.carro.modelo = `${value.marca} ${value.modelo}`;
      newState.carro.placa = value.placa;
      newState.carro.chassis = value.chassis;

      const latestContract = this.getLastContract(value.contratos, value);

      if (latestContract) {
        newState.contract.produto = latestContract.produto.descricao;
        newState.contract.vigenciaInicio = `De ${latestContract.dataInicioVigencia}`;
        newState.contract.vigenciaFinal = `à ${latestContract.dataFinalVigencia}`;
        newState.contract.frequencias = latestContract.produto.frequencias;
      }

      const {
        enderecoFavorito: enderecoConcessionaria = {},
        dataFinalGarantia = '',
        dataFinalGarantiaEstendida1 = '',
        descricaoGarantiaEstendida1 = '',
        dataFinalGarantiaEstendida2 = '',
        descricaoGarantiaEstendida2 = '',
        chassis: chassi = '',
      } = value;

      const {
        descricao = '',
        endereco: {logradouro} = {},
        cliente: {premium = value.cliente.premium || false} = {},
        contato = '',
        email = '',
        telefoneContato = '',
        contato2 = '',
        telefoneContato2 = '',
        contato3 = '',
        telefoneContato3 = '',
        contatosEnderecoFavorito,
      } = enderecoConcessionaria;
      this.props.setAttendanceValidateInfo({
        chassi,
        concessionaria: descricao && logradouro ? `${descricao} | ${logradouro}` : '',
        premium,
        email,
        dataFinalGarantia,
        contato,
        telefoneContato,
        contato2,
        telefoneContato2,
        contato3,
        telefoneContato3,
        dataFinalGarantiaEstendida1,
        descricaoGarantiaEstendida1,
        dataFinalGarantiaEstendida2,
        descricaoGarantiaEstendida2,
        contatosEnderecoFavorito,
      });

      newState.showInfo = true;
      newState.index = 0;
    } else {
      newState.nome = '';
      newState.document = '';
      newState.carro.modelo = '';
      newState.carro.placa = '';
      newState.contract.produto = '';
      newState.contract.vigenciaInicio = '';
      newState.contract.vigenciaFinal = '';
      newState.contract.frequencias = [];
      newState.showInfo = false;
      newState.index = 1;
      this.props.resetAttendanceValidateInfo();
    }

    this.setState(newState, () => {
      if (this.state.veiculoCliente) {
        this.getCienteSolicitation();
      }
    });
  }

  handleTypeRequest(event) {
    const {value: requisicaoTipo} = event.target;
    if (requisicaoTipo === 'REQUISICAOTIPOASSISTENCIA') {
      this.setState({
        ...this.state,
        requisicaoTipo,
        tipoMotivo: {
          label: 'Selecione o tipo de assistência',
          value: '',
        },
      });
    } else if (requisicaoTipo === 'REQUISICAOTIPORECUSA') {
      this.setState({
        ...this.state,
        requisicaoTipo,
        tipoMotivo: {
          label: 'Selecione o tipo de inconformidade',
          value: '',
        },
      });
    } else if (requisicaoTipo === 'REQUISICAOTIPOSUPORTE') {
      this.setState({
        ...this.state,
        requisicaoTipo,
        areaSuporte: '',
        tipoReclamacao: {
          label: 'Selecione o tipo do suporte',
          value: '',
        },
        tipoMotivo: {
          label: 'Selecione a área de suporte',
          value: '',
        },
      });
    } else {
      this.setState({
        ...this.state,
        requisicaoTipo,
        tipoMotivo: {
          label: 'Selecione o tipo de SAC',
          value: '',
        },
      });
    }
    this.handleShowTipoRevisao(requisicaoTipo);
  }

  handleShowTipoRevisao(requisicaoTipo) {
    const {setAttendanceDisplayRevision} = this.props;
    const agendamento = 'REQUISICAOTIPOAGENDAMENTO';

    if (this.props.showTipoRevisao && requisicaoTipo !== agendamento) {
      setAttendanceDisplayRevision(false);
      return;
    }

    if (requisicaoTipo === agendamento) {
      setAttendanceDisplayRevision(true);
    }
  }

  formatContractStatus(status) {
    if (!status || !status.codigo) {
      return <div />;
    }

    const {codigo} = status;
    let className = '';

    switch (codigo) {
      case 'PLANOATIVO':
        className = 'primary';
        break;

      case 'PLANOINATIVO':
        className = 'danger';
        break;

      case 'PLANOSUSPENSO':
        className = 'warning';
        break;
    }

    return (
      <span style={{marginLeft: 5}} className={`badge badge-${className}`}>
        {status.descricao}
      </span>
    );
  }

  isKoviUser() {
    const {login} = this.props;

    const koviId = '2200248530130441273';
    return login.user_info.empresa.codigo === koviId;
  }

  isKoviSelected() {
    const {company} = this.state;

    if (company && company.value) {
      const koviId = '2200248530130441273';
      return company.value === koviId;
    }

    return false;
  }

  isDAFUser() {
    const {login} = this.props;

    const dafId = '2322661870558778503';
    return login.user_info.empresa.codigo === dafId;
  }

  isDAFSelected() {
    const {company} = this.state;
    console.log(company);

    if (company && company.value) {
      const dafId = '2322661870558778503';
      return company.value === dafId;
    }

    return this.isDAFUser();
  }

  isInconformidadeSelected() {
    const {requisicaoTipo} = this.state;

    if (requisicaoTipo && requisicaoTipo === 'REQUISICAOTIPORECUSA') {
      return true;
    }
    return false;
  }

  getComplaintOptions() {
    return complaintOptions;
  }

  getUsers() {
    let userInfo = JSON.parse(localStorage.getItem('user_info'));
    const user = getUsers(userInfo.usuario.nome);
    return user;
  }

  async supportTypeRequest() {
    const {data: typesSupport} = await typeSupport();
    const suporte = {};
    const areasSuporte = typesSupport.filter((type) => type.type === 'AreaSuporte');
    const tiposSuporte = typesSupport.filter((type) => type.type !== 'AreaSuporte');

    areasSuporte.forEach(
      (area) =>
        (suporte[area.code.slice(4)] = {
          description: area.description,
          code: area.code,
          types: [],
        }),
    );
    tiposSuporte.forEach((type) => {
      const position = type.type.slice(4).toUpperCase();
      const newObject = {label: type.description, value: type.code};
      suporte[position].types.push(newObject);
    });

    return suporte;
  }

  verifyLogin() {
    const {login} = this.props;
    const userLogin = login.user_info.usuario.id;
    const usuarioResponsavel =
      this.state.usuarioResponsavel && this.state.usuarioResponsavel.id;
    if (
      hasProfiles([
        APP_PROFILES.SupervisorRede,
        APP_PROFILES.Administrador,
        APP_PROFILES.FinanceiroSoon,
        APP_PROFILES.AnalistaRede,
      ])
    )
      return true;
    if (hasProfiles([APP_PROFILES.Atendente]) && usuarioResponsavel === userLogin) return true;
    return false;
  }

  onRowClick() {
    const base = this.state.selectedBase.codigo;
    const codigo = this.state.codigo;
    if (!base) return;

    const newUrl = `/v2/assistance/${codigo}/details?tab=Base&baseCode=${base}&baseModalTab=Fechamento`;

    if (window.parent) {
      window.parent.history.pushState({}, '', newUrl);
    } else {
      console.error(
        'Acesso negado à janela pai (provavelmente devido à política de mesma origem)',
      );
    }

    const popStateEvent = new PopStateEvent('popstate', {state: {}});
    window.parent.dispatchEvent(popStateEvent);
  }

  createBaseOptions() {
    return this.state.bases.map((partner) => {
      return {
        value: partner.code,
        baseid: partner.id,
        label: `${partner.tradingName} - ${partner.contact}`,
      };
    });
  }

  async componentDidMount() {
    const users = await this.getUsers();
    const {bases} = await filterBases(null, {_status: 'BASEATIVA,BASEINATIVA'}, 1, 10000);
    let departaments;
    if (users && users.data.length > 0) {
      departaments = users.data[0].departments;
    }
    if (this.isKoviUser()) {
      this.setState({
        ...this.state,
        requisicaoTipo: 'REQUISICAOTIPOASSISTENCIA',
      });
    }
    const suporte = await this.supportTypeRequest();
    this.setState({
      ...this.state,
      departaments,
      isFirstLoad: false,
      suporte,
      bases,
    });
  }

  render() {
    const {type, login} = this.props;
    const {index, contract, showInfo} = this.state;
    const tipoUsuario = login.user_info.usuario.tipoUsuario;
    const nomeEmpresa = login.user_info.empresa.nomeFantasia;
    const isInconformidade = this.state.codigoInconformidade;
    this.companiesOptions = this.buildCompaniesOptions();

    const contrato =
      this.state.veiculoCliente &&
      this.state.veiculoCliente.value &&
      this.state.veiculoCliente.value.contrato;
    const cargaDaf = this.props.attendanceTab.tipoCargaDaf;

    const isDaf = () => {
      let companyCode = '';
      let userInfo = JSON.parse(localStorage.getItem('user_info'));
      if (this.state.company) {
        companyCode = this.state.company.value;
      } else {
        companyCode = userInfo.empresa.codigo;
      }
      return companyCode === CODIGO_DAF;
    };

    const bases = this.createBaseOptions();
    return (
      <div className="ibox">
        <div className="ibox-content" style={{overflow: 'auto'}}>
          <form onSubmit={this.handleSubmit}>
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6 form-group base-register-container">
                  <div className="col-sm-12" style={{marginBottom: '10px'}}>
                    <div className="form-group">
                      <label className="font-normal p-w-xs">Empresa</label>
                      <div className="input-group">
                        <span className="input-group-addon">
                          <i className="fas fa-building" />
                        </span>
                        {tipoUsuario === USUARIO_TIPO_EMPRESA ? (
                          <Select
                            options={this.companiesOptions}
                            placeholder={nomeEmpresa}
                            styles={{...companiesStyles, ...selectCustomStyle}}
                            value={this.state.company}
                            isDisabled
                          />
                        ) : (
                          <Select
                            options={this.companiesOptions}
                            placeholder="Todas as empresas"
                            styles={{...companiesStyles, ...selectCustomStyle}}
                            onChange={(company) => {
                              this.setState({
                                ...this.state,
                                company,
                                requisicaoTipo: '',
                              });
                            }}
                            value={this.state.company}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12" style={{marginBottom: '10px'}}>
                    <label className="font-normal p-w-xs">Tipo de Requisição</label>
                    <div className="input-group col-md-12">
                      <span className="input-group-addon">
                        <i className="fas fa-clipboard-list" />
                      </span>
                      <select
                        onChange={this.handleTypeRequest}
                        className="form-control"
                        value={this.state.requisicaoTipo}>
                        <option value="">Escolha um tipo de requisição</option>
                        <option value="REQUISICAOTIPOINFORMACAO">Requisição de SAC</option>
                        <option value="REQUISICAOTIPOASSISTENCIA">
                          Requisição de Assistência
                        </option>
                        <option value="REQUISICAOTIPOAGENDAMENTO">
                          Requisição de Agendamento
                        </option>
                        <option value="REQUISICAOTIPORECUSA">
                          Requisição de inconformidade
                        </option>
                        {this.state.company && this.state.company.value === '2' && (
                          <option value="REQUISICAOTIPOSUPORTE">Requisição de Suporte</option>
                        )}
                      </select>
                    </div>
                  </div>
                  {this.state.requisicaoTipo &&
                    this.state.requisicaoTipo === 'REQUISICAOTIPOASSISTENCIA' && (
                      <div
                        className="col-sm-12"
                        style={{marginBottom: '10px', marginTop: '10px'}}>
                        <div className="form-group">
                          <label className="font-normal p-w-xs">Tipo de Assistência</label>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="fas fa-car-crash" />
                            </span>
                            <Select
                              options={
                                this.isKoviUser() || this.isKoviSelected()
                                  ? tipoMotivoKoviOptions(this.state)
                                  : this.isDAFUser() || this.isDAFSelected()
                                  ? tipoMotivoDAFOptions
                                  : tipoMotivoOptions
                              }
                              placeholder="Selecione o tipo de assistência"
                              styles={{...companiesStyles, ...selectCustomStyle}}
                              onChange={(tipoMotivo) => {
                                this.setState({
                                  ...this.state,
                                  tipoMotivo,
                                });
                              }}
                              value={this.state.tipoMotivo}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  {this.state.requisicaoTipo &&
                    this.state.requisicaoTipo === 'REQUISICAOTIPOINFORMACAO' && (
                      <div
                        className="col-sm-12"
                        style={{marginBottom: '10px', marginTop: '10px'}}>
                        <div className="form-group">
                          <label className="font-normal p-w-xs">Tipo de SAC</label>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="fas fa-car-crash" />
                            </span>
                            <Select
                              options={this.getSacOptions()}
                              placeholder="Selecione o tipo de SAC"
                              styles={{...companiesStyles, ...selectCustomStyle}}
                              onChange={(tipoMotivo) => {
                                this.setState({
                                  ...this.state,
                                  tipoMotivo,
                                });
                              }}
                              value={this.state.tipoMotivo}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  {this.state.requisicaoTipo &&
                    this.state.requisicaoTipo === 'REQUISICAOTIPORECUSA' && (
                      <div
                        className="col-sm-12"
                        style={{marginBottom: '10px', marginTop: '10px'}}>
                        <div className="form-group">
                          <label className="font-normal p-w-xs">Tipo de Inconformidade</label>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="fas fa-car-crash" />
                            </span>
                            <Select
                              options={this.getRecusaOptions()}
                              placeholder="Selecione o tipo de inconformidade"
                              styles={{...companiesStyles, ...selectCustomStyle}}
                              onChange={(tipoMotivo) => {
                                this.setState({
                                  ...this.state,
                                  tipoMotivo,
                                });
                              }}
                              value={this.state.tipoMotivo}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  {this.state.tipoMotivo &&
                    this.state.tipoMotivo.value === 'SACRECLAMACAO' &&
                    isDaf() && (
                      <div
                        className="col-sm-12"
                        style={{marginBottom: '10px', marginTop: '10px'}}>
                        <div className="form-group">
                          <label className="font-normal p-w-xs">Tipo de Reclamação</label>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="fas fa-bullhorn" />
                            </span>
                            <Select
                              options={this.getComplaintOptions()}
                              placeholder="Selecione o tipo de reclamação"
                              styles={{...companiesStyles, ...selectCustomStyle}}
                              onChange={(tipoReclamacao) => {
                                this.setState({
                                  ...this.state,
                                  tipoReclamacao,
                                });
                              }}
                              value={this.state.tipoReclamacao}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  {this.state.requisicaoTipo &&
                    this.state.requisicaoTipo === 'REQUISICAOTIPOSUPORTE' && (
                      <div
                        className="col-sm-12"
                        style={{marginBottom: '10px', marginTop: '10px'}}>
                        <div className="form-group">
                          <label className="font-normal p-w-xs">Área de Suporte</label>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="fas fa-headphones" />
                            </span>
                            <Select
                              options={this.getSupportOptions()}
                              placeholder="Selecione a área de suporte"
                              styles={{...companiesStyles, ...selectCustomStyle}}
                              onChange={(tipoMotivo) => {
                                this.setState({
                                  ...this.state,
                                  tipoMotivo,
                                  tipoReclamacao: {
                                    label: 'Selecione o tipo do suporte',
                                    value: '',
                                  },
                                  areaSuporte: tipoMotivo.value,
                                });
                              }}
                              value={this.state.tipoMotivo}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  {this.state.requisicaoTipo &&
                    this.state.requisicaoTipo === 'REQUISICAOTIPOSUPORTE' && (
                      <div
                        className="col-sm-12"
                        style={{marginBottom: '10px', marginTop: '10px'}}>
                        <div className="form-group">
                          <label className="font-normal p-w-xs">Tipo do Suporte</label>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="fas fa-bullhorn" />
                            </span>
                            <Select
                              options={this.getSupportTypeOptions()}
                              placeholder="Selecione o tipo do suporte"
                              styles={{...companiesStyles, ...selectCustomStyle}}
                              onChange={(tipoReclamacao) => {
                                this.setState({
                                  ...this.state,
                                  tipoReclamacao,
                                });
                              }}
                              value={this.state.tipoReclamacao}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  <div className="col-sm-12" style={{marginBottom: '10px'}}>
                    <label className="font-normal p-w-xs">Origem</label>
                    <div className="input-group col-md-12">
                      <span className="input-group-addon">
                        <i className="fas fa-envelope" />
                      </span>
                      <select
                        onChange={(event) => {
                          const {value: origem} = event.target;
                          this.setState({
                            ...this.state,
                            origem,
                          });
                        }}
                        className="form-control"
                        value={this.state.origem}>
                        <option value="">Escolha a origem</option>
                        <option value="REQUISICAOORIGEMLIGACAO">Ligação Telefônica</option>
                        <option value="REQUISICAOORIGEMAPLICATIVO">Aplicativo</option>
                        <option value="REQUISICAOORIGEMCHAT">Chat no Site</option>
                        <option value="REQUISICAOORIGEMWHATSAPP">WhatsApp</option>
                        <option value="REQUISICAOORIGEMCHATBOT">ChatBot</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div>
                      <label className="font-normal p-w-xs">Contato</label>
                      <div className="form-group">
                        <div className="input-group">
                          <span className="input-group-addon">
                            <i className="far fa-id-card" />
                          </span>
                          <div className="input-group-append">
                            {/* <MaskedInput
                              id="telefoneCelular"
                              onChange={(e) =>
                                this.setState({
                                  ...this.state,
                                  contact: e.target.value,
                                })
                              }
                              className="form-control"
                              value={this.state.contact}
                              mask="(99) 9999tt999?"
                              formatChars={{
                                "9": "[0-9]",
                                t: "[0-9-]",
                                "?": "[0-9 ]",
                              }}
                              maskChar={null}
                            /> */}
                            <input
                              className="form-control"
                              onChange={(e) =>
                                this.setState({
                                  ...this.state,
                                  contact: e.target.value,
                                })
                              }
                              value={this.state.contact}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div>
                      <div className="form-group">
                        <label className="font-normal p-w-xs">Telefone</label>
                        <div className="input-group">
                          <span className="input-group-addon">
                            <i className="fas fa-mobile" />
                          </span>
                          <MaskedInput
                            id="telefoneCelular"
                            onChange={(e) =>
                              this.setState({
                                ...this.state,
                                phone: e.target.value,
                              })
                            }
                            className="form-control"
                            value={this.state.phone}
                            mask="(99) 9 9999-9999"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {!this.props.attendanceId &&
                  this.isDAFSelected() &&
                  this.isInconformidadeSelected() && (
                    <div className="col-sm-6 form-group base-register-container">
                      <div style={{marginTop: '10px'}} className="col-sm-12">
                        <label className="font-normal p-w-xs">
                          Atendimento em inconformidade
                        </label>
                        <div className="input-group" style={{width: '50%'}}>
                          <span className="input-group-addon">
                            <i className="fas fa-file-alt" />
                          </span>
                          <input
                            id="Codigo da solicitação"
                            required
                            onChange={(e) =>
                              this.setState({
                                ...this.state,
                                codigoAtendimentoInconformidade: e.target.value,
                              })
                            }
                            className="form-control"
                            value={this.state.codigoAtendimentoInconformidade}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                <div className="col-sm-6 form-group base-register-container">
                  {this.state.requisicaoTipo !== 'REQUISICAOTIPOSUPORTE' && (
                    <div className="col-sm-12">
                      <label className="font-normal p-w-xs">Veículo/Cliente</label>
                      <div className="input-group">
                        <span className="input-group-addon">
                          <i className="fas fa-car-side" />
                        </span>
                        <AsyncSelect
                          value={this.state.veiculoCliente}
                          loadOptions={this.getClientNameOptions}
                          onChange={this.onChangeVeiculoCliente}
                          isClearable
                        />
                      </div>
                    </div>
                  )}
                  {this.state.requisicaoTipo &&
                    this.state.requisicaoTipo === 'REQUISICAOTIPOSUPORTE' &&
                    this.state.company &&
                    this.state.company.value === '2' && (
                      <div className="col-md-12">
                        <label className="font-normal p-w-xs">Base</label>
                        <div className="input-group">
                          <span className="input-group-addon">
                            <i className="fas fa-warehouse"></i>
                          </span>
                          <Select
                            value={
                              this.props.attendanceId &&
                              this.state.selectedBase && {
                                label: `${this.state.selectedBase.nomeFantasia} - ${this.state.selectedBase.contato}`,
                              }
                            }
                            onChange={(base) =>
                              this.setState({
                                ...this.state,
                                selectedBaseCode: base ? base.value : null,
                              })
                            }
                            options={!this.props.attendanceId ? bases : []}
                            isClearable={!this.props.attendanceId}
                          />
                        </div>
                      </div>
                    )}

                  <div />
                  <section
                    className="col-sm-12"
                    style={{
                      marginTop: '20px',
                      display: showInfo ? 'flex' : 'none',
                      alignItems: 'center',
                    }}>
                    <i className="fas fa-2x fa-user" />
                    <div
                      style={{
                        marginLeft: '20px',
                      }}>
                      <h4>{this.state.nome}</h4>
                      <p>{this.state.document}</p>
                    </div>
                  </section>

                  <section
                    className="col-sm-12"
                    style={{
                      marginTop: '20px',
                      display: showInfo ? 'flex' : 'none',
                      alignItems: 'center',
                    }}>
                    <i className="fas fa-2x fa-car-side" />
                    <div
                      style={{
                        marginLeft: '15px',
                      }}>
                      <h4>{this.state.carro.modelo}</h4>
                      <p>
                        {this.state.carro.placa
                          ? this.state.carro.placa
                          : this.state.carro.chassis}
                      </p>
                    </div>
                  </section>

                  <section
                    className="col-sm-12"
                    style={{
                      marginTop: '10px',
                      display: showInfo && contract.produto ? 'flex' : 'none',
                      alignItems: 'center',
                    }}>
                    <i className="fas fa-2x fa-file" />
                    <div
                      style={{
                        marginLeft: '25px',
                      }}>
                      <h4>
                        {contract.produto}
                        {contrato &&
                          contrato.status &&
                          this.formatContractStatus(contrato.status)}
                      </h4>
                      <p>
                        {contract.vigenciaInicio} {contract.vigenciaFinal}
                      </p>
                      {contrato && (contrato.kmInicio || contrato.kmFinal) && (
                        <p>
                          Km Contratado: {contrato.kmInicio} à {contrato.kmFinal}
                        </p>
                      )}
                    </div>
                  </section>
                  {isInconformidade && (
                    <section
                      className="col-sm-12"
                      style={{
                        marginLeft: '10px',
                        alignItems: 'center',
                      }}>
                      <a onClick={this.handleClick}> Ver Atendimento em inconformidade</a>
                    </section>
                  )}
                  <section
                    className="col-sm-12"
                    style={{
                      display: 'flex',
                      marginTop: '10px',
                      marginLeft: '7px',
                      gap: '10px',
                    }}>
                    {this.props.attendanceId && this.isDAFSelected() && (
                      <button
                        className="btn btn-warning mt-2"
                        onClick={() => this.changeCliente()}>
                        Alterar Cliente
                      </button>
                    )}
                    {this.props.attendanceId && this.isDAFSelected() && !isInconformidade && (
                      <button
                        className="btn btn-danger mt-2"
                        onClick={() => doOpenModal('CONTEXT_CRIAR_INCONFORMIDADE')}>
                        Gerar Inconformidade
                      </button>
                    )}
                    {this.props.attendanceId && !this.state.usuarioResponsavel && (
                      <button
                        className="btn btn-primary mt-2"
                        onClick={this.pegarResponsabilidade}>
                        Pegar Atendimento
                      </button>
                    )}
                    {this.props.attendanceId && this.verifyLogin() && this.state.selectedBase && (
                      <button
                        style={{
                          cursor:
                            this.verifyLogin() &&
                            this.props.attendanceId &&
                            this.state.selectedBase
                              ? 'pointer'
                              : 'default',
                        }}
                        className="btn btn-primary mt-2"
                        onClick={(e) => {
                          e.preventDefault();
                          this.onRowClick();
                        }}>
                        Ver Base
                      </button>
                    )}
                  </section>

                  <CustomModal
                    title="Gerar inconformidade"
                    context={'CONTEXT_CRIAR_INCONFORMIDADE'}>
                    <div
                      className="col-md-12"
                      style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                      <div
                        className="col-sm-12"
                        style={{marginBottom: '10px', marginTop: '10px'}}>
                        <div className="form-group">
                          <label className="font-normal p-w-xs">Tipo de inconformidade</label>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="fas fa-car-crash" />
                            </span>
                            <Select
                              options={recusaOptions}
                              styles={{...companiesStyles, ...selectCustomStyle}}
                              onChange={(tipoMotivo) => {
                                this.setState({
                                  ...this.state,
                                  tipoMotivoInconformidade: tipoMotivo,
                                });
                              }}
                              value={this.state.tipoMotivoInconformidade}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-sm-12">
                        <label className="font-normal p-w-xs">Motivo Inconformidade</label>
                        <textarea
                          id="failureTextarea"
                          value={this.state.failureInconformidade}
                          onChange={(e) => {
                            this.onChangeFailure(e.target.value);
                          }}
                          rows="6"
                          className="form-control"
                        />
                      </div>
                      <button
                        className="btn btn-primary mt-2"
                        onClick={() =>
                          this.criarInconformidade(
                            this.state.tipoMotivoInconformidade,
                            this.state.failureInconformidade,
                          )
                        }>
                        Criar atendimento de inconformidade
                      </button>
                    </div>
                  </CustomModal>
                </div>
              </div>
              <div className="row">
                <Wizard index={index} handleTab={(index) => this.setState({index})}>
                  <Step hide={!showInfo} title="Validação">
                    <ValidationTab />
                  </Step>
                  <Step title="Detalhes">
                    <FailureTab
                      veiculoCliente={this.state.veiculoCliente}
                      company={this.state.company}
                      cargaDaf={cargaDaf}
                    />
                  </Step>
                  <Step title="Endereços">
                    <AttendenceAddressTab
                      company={this.state.company}
                      attendanceId={this.props.attendanceId}
                      companyName={this.state.company}
                      updateAtendimentoTab={this.props.updateAtendimentoTab}
                    />
                  </Step>
                  <Step hide={!showInfo} title="Plano">
                    <ServicesTab contract={this.state.contract} />
                  </Step>
                </Wizard>
              </div>

              <div className="control-buttons col-md-4">
                <div className="row">
                  <div className="col-md-6">
                    <button
                      type="button"
                      className={classnames({
                        'btn btn-block  btn-warning': true,
                        hide: type !== 'create',
                      })}
                      onClick={this.handleClear}>
                      Reset
                    </button>
                  </div>
                  <div className="col-md-6">
                    <button
                      type="submit"
                      style={{display: type === 'create' ? 'block' : 'none'}}
                      disabled={this.props.newAttendaceFetching}
                      className="btn btn-block btn-primary">
                      {type === 'create' ? 'Cadastrar' : 'Atualizar'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  companies: state.relatorio.companies,
  failure: state.attendance.createFailureTab,
  actualKm: state.attendance.actualKm,
  distance: state.attendance.estimation.distance,
  distanceUnit: state.attendance.estimation.distanceUnit,
  time: state.attendance.estimation.timeUnit,
  originAddress: state.attendance.originAddress,
  endereco: state.service.enderecoOrigem,
  enderecoDestino: state.service.enderecoDestino,
  attendanceTab: state.attendance.attendanceTab,
  newAttendaceFetching: state.attendance.newAttendanceFetching,
  tipoCarga: state.attendance.tipoCarga,
  showTipoRevisao: state.attendance.showTipoRevisao,
  tipoRevisao: state.attendance.tipoRevisao,
  login: state.login,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getCompanies,
      onChangeFailureDescription,
      clearAddress,
      newAttendence,
      clearServiceState,
      clearAttendanceTab,
      onChangeActualKm,
      onChangeEstimationDistance,
      onChangeEstimationTime,
      setAttendanceChargeType,
      setAttendanceOriginAddress,
      onAddressChange,
      setAttendanceEstimation,
      setAttendanceValidateInfo,
      resetAttendanceValidateInfo,
      setAttendanceDisplayRevision,
      setAttendanceTypeOfRevision,
      pureAddressChange,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(RegisterAttendence);
