import React, {Component} from 'react';

import GoogleMapReact from 'google-map-react';

import GoogleMarker from './GoogleMarker';
import {sendMapNotification} from '../../api/heatMapHelper';

const icons = {
  tow: 'https://s3-sa-east-1.amazonaws.com/files.reboque.me/images/carBlue.png',
  origin: 'https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi2.png',
  destination: 'http://icons.iconarchive.com/icons/fatcow/farm-fresh/32/flag-finish-icon.png',
  car: 'https://s3-sa-east-1.amazonaws.com/files.reboque.me/images/carro.svg',
  agreeTravelIcon: 'https://freeimage.host/i/2CfnAg',
  help: 'https://s3.us-east-1.amazonaws.com/files.soon.com.vc/images/hand.svg',
};
const defaultProps = {
  center: {
    lat: -21.9794231,
    lng: -47.5126307,
  },
  zoom: 10,
};
const InfoBox = (props) => (
  <div className="info-box talkbubble">
    {props.solicitation ? (
      <a
        onClick={(e) => {
          e.preventDefault;
          props.seeOnMap(props.solicitation);
        }}>
        {props.text}
      </a>
    ) : (
      props.text
    )}
  </div>
);

const NotifyPartnerBox = (props) => {
  const foundPartner = props.calledPartner.find((partner) => partner.versaoAppV2);
  const versaoApp = foundPartner ? foundPartner.versaoAppV2 : 'App antigo';

  return (
    <div className="info-box-2 talkbubble">
      <div>
        <p className="no-margin single-lines">
          <strong>Versão do App</strong>
        </p>
        <i style={{marginBottom: '4px'}}>{versaoApp}</i>
        <p className="no-margin single-lines">
          <strong>Última atualização</strong>
        </p>
        <i>{props.partner.dataUltimaPosicao}</i>
      </div>
      <button
        className="btn btn-xs btn-success"
        title="Olá, está tudo certo com o atendimento?"
        style={{marginTop: '5px'}}
        onClick={() =>
          sendMapNotification(props.partner.codigo, 'Olá, está tudo certo com o atendimento?')
        }>
        <i className="fa fa-paper-plane" style={{marginRight: '4px'}} />
        Notificar
      </button>
    </div>
  );
};

class SimpleMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      infoBoxKey: 0,
      infoBoxLat: 0,
      infoBoxLng: 0,
      infoBoxVisible: false,
      notifyPartnerBoxLat: 0,
      notifyPartnerBoxLng: 0,
      notifyPartnerBoxVisible: false,
    };
    this.onMarkerClick = this.onMarkerClick.bind(this);
    this.handleInfoBoxVisibility = this.handleInfoBoxVisibility.bind(this);
    this.onPartnerMarkerClick = this.onPartnerMarkerClick.bind(this);
  }

  onMarkerClick(infoBoxKey, infoBoxLat, infoBoxLng, address) {
    this.setState({
      infoBoxKey,
      infoBoxLat,
      infoBoxLng,
      address,
      infoBoxVisible: true,
    });
  }
  onPartnerMarkerClick() {
    this.setState({
      notifyPartnerBoxVisible: true,
    });
  }
  handleInfoBoxVisibility() {
    this.setState({infoBoxVisible: false, notifyPartnerBoxVisible: false});
  }
  handleApiLoaded(map, maps) {
    map.set('gestureHandling', 'greedy');
  }

  render() {
    const {
      partnerLocation,
      requestOrigin,
      agreeTravelAt,
      requestDestination,
      zoom,
      markList,
      solicitation,
      seeOnMap,
      calledPartners,
    } = this.props;
    return (
      // Important! Always set the container height explicitly
      <div style={this.props.style}>
        <GoogleMapReact
          ref={(ref) => (this.map = ref)}
          bootstrapURLKeys={{key: process.env.REACT_APP_MAPS_KEY}}
          defaultCenter={partnerLocation ? partnerLocation : defaultProps.center}
          defaultZoom={defaultProps.zoom}
          zoom={markList ? 5 : zoom}
          onClick={() => this.handleInfoBoxVisibility()}
          onGoogleApiLoaded={({map, maps}) => this.handleApiLoaded(map, maps)}>
          {this.state.infoBoxVisible ? (
            <InfoBox
              lat={this.state.infoBoxLat}
              lng={this.state.infoBoxLng}
              text={this.state.address}
              seeOnMap={seeOnMap}
              solicitation={solicitation}
            />
          ) : null}

          {markList
            ? markList.map((mark, i) => {
                return (
                  <GoogleMarker
                    key={i}
                    icon={mark.ajudaNecessaria ? icons.help : icons.car}
                    lat={mark.lat}
                    lng={mark.lng}
                    address={mark.logradouro}
                    onClick={this.onMarkerClick}
                  />
                );
              })
            : null}

          {agreeTravelAt ? (
            <GoogleMarker
              icon={require('../../assets/linha-de-largada.png')}
              lat={agreeTravelAt.lat}
              lng={agreeTravelAt.lng}
            />
          ) : null}

          {partnerLocation ? (
            <GoogleMarker
              icon={icons.tow}
              lat={partnerLocation.lat}
              lng={partnerLocation.lng}
              bearing={partnerLocation.bearing}
              onClick={this.onPartnerMarkerClick}>
              {this.state.notifyPartnerBoxVisible ? (
                <NotifyPartnerBox
                  lat={partnerLocation.lat}
                  lng={partnerLocation.lng}
                  partner={solicitation.respostaAceita.parceiro}
                  calledPartner={calledPartners}
                />
              ) : null}
            </GoogleMarker>
          ) : null}

          {requestDestination ? (
            <GoogleMarker
              icon={icons.destination}
              lat={requestDestination.lat}
              lng={requestDestination.lng}
              onClick={this.onMarkerClick}
              address={requestDestination.logradouro}
            />
          ) : null}

          {requestOrigin ? (
            <GoogleMarker
              icon={icons.car}
              lat={requestOrigin.lat}
              lng={requestOrigin.lng}
              onClick={this.onMarkerClick}
              address={requestOrigin.logradouro}
            />
          ) : null}
        </GoogleMapReact>
      </div>
    );
  }
}

export default SimpleMap;
